import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, Icon, Input, Modal, Toast} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notify from './notify';

class EditTemplate extends Component {
  constructor() {
    super()

    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.state = {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      team: '',
      userId: '',
      utmsBuild: [],
      fullUTM: '',
      templateId: '',
      templateName: '',
      forceLowerCase: true
    }

  }

    componentDidMount() {
      this.setState({
        source: this.props.data.source,
        medium: this.props.data.medium,
        campaign: this.props.data.campaign,
        term: this.props.data.term,
        content: this.props.data.content,
        fullUTM: this.props.data.templateString,
        templateName: this.props.data.templateName,
        templateId: this.props.templateId
      })
    }


handleTemplateChange(e) {
    e.preventDefault();
    //if the url and source and medium are all filled in.
    if(this.state.source.length > 0 && this.state.medium.length > 0) {

    const db = firebase.firestore();
    
    
    const passState = this.state;
    const passProps = this.props;
    const fullUTM = this.state.forceLowerCase ?
     `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`.toLowerCase() :
     `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`

    let templateId = this.props.templateId;
     db.collection("template").doc(templateId).update({
        campaign: passState.campaign,
        content: passState.content,
        lastEdited: firebase.firestore.Timestamp.fromDate(new Date()),
        lastEditedBy: fire.auth().currentUser.uid,
        medium: passState.medium,
        source: passState.source,
        templateName: passState.templateName,                   
        term: passState.term,
        templateString: this.state.forceLowerCase ?
            `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`.toLowerCase() :
            `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`
          
      })

      this.props.updateParent(this.props.index,this.state.value, "templates")

    } else {
      toast("URL, Source, Medium, campaign and template name are required fields", {
        draggablePercent: 60,
        className: 'notify-error',
        bodyClassName: "notify-errortext",
        progressClassName: 'notificationProgressBar'
      })
    }

  }

  handleChange(e) {
    const db = firebase.firestore();
    this.setState({
      [e.target.name]: e.target.value,
      userId: db.doc('user/' + fire.auth().currentUser.uid),
      
    });
  }

  handleSwitch(e) {
    this.setState({
      forceLowerCase: !this.state.forceLowerCase
    })
  }


render() {

    return(
      <Modal header="" fixedFooter trigger={
      // <Button className="primary ghostButton align-center"><Icon>add</Icon> Add new UTM</Button>
      <Button icon='edit' tooltip="Edit template" className='ghostButtonNoBorder waves'></Button>
      }>

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Edit your template "{this.props.data.templateName}".</h4>
                <label>Name</label>
                <Input type="text" name="templateName" s={12} value={this.state.templateName} onChange={this.handleChange}/>
                <label>Source</label>
                <Input type="text" name="source" s={12} value={this.state.source} onChange={this.handleChange}/>
                <label>Medium</label>
                <Input type="text" name="medium" s={12} value={this.state.medium} onChange={this.handleChange}/>
                <label>campaign</label>
                <Input type="text" disabled name="campaign" s={12} value={this.state.campaign} onChange={this.handleChange}/>
                <label>term</label>
                <Input type="text" name="term" s={12} value={this.state.term} onChange={this.handleChange}/>
                <label>content</label>
                <Input type="text" name="content" s={12} value={this.state.content} onChange={this.handleChange}/>
                <p>Force lowercase</p>
                <Input type="switch" label="forceLowerCase" name="forceLowerCase" s={12} checked={this.state.forceLowerCase} onChange={this.handleSwitch}/>

                <Col s={10} m={10} l={10} offset="l1 m1 s1" className="left-align grey-text">
                  <h5>{this.state.forceLowerCase ?
                    `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`.toLowerCase() :
                    `${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`
                  }</h5>
                </Col>
              <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary modal-action modal-close" id="logIn" onClick={this.handleTemplateChange}>
                    Update
                  </Button>
                </Col>
                </Row>
          </Col>
        </Modal>
    )
  }
}

export default EditTemplate
