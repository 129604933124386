import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, Icon, Input, Modal} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

class DeleteItem extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.state = {
      campaign: '',
      team: '',
      userId: '',
      showError: false,
      errorMessage: '',
      delete: '',
      item: '',
      itemId: ''
    }

  }

    componentDidMount() {
     

    }

  deleteItem() {
    if (this.state.delete == "DELETE") {

    const db = firebase.firestore();
    const campaignId = this.props.id;

    //if the deleted item is a campaign
    if (this.props.type == "campaign") {
      

      db.collection('utm').where("campaignId", "==", this.props.id).get()
        .then(function(querySnapshot) {
          // Once we get the results, begin a batch
          var batch = db.batch();

          querySnapshot.forEach(function(doc) {
              // For each doc, add a delete operation to the batch
              batch.delete(doc.ref);
          });

          // Commit the batch
          return batch.commit();
        }).then(function(){
          //delete templates attached to this campaign
          
          db.collection('template').where("campaignId", "==", campaignId).get()
        .then(function(querySnapshot) {
          // Once we get the results, begin a batch
          var batch = db.batch();

          querySnapshot.forEach(function(doc) {
              // For each doc, add a delete operation to the batch
              batch.delete(doc.ref);
          });

          // Commit the batch
          return batch.commit();
          })
        }).then(function() {
          
          //delete the campaign itself
          db.collection("campaign").doc(campaignId).delete().then(function() {
          //redirect
          
            toast("🤖 Campaign and utms deleted!", {
              draggablePercent: 60,
              className: 'notify-delete',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
              //redirect to campaigns
              window.location.pathname = '/campaigns';
            })
            .catch(function(error) {
              console.error("Error removing campaign: ", error);
            toast("🤖 Error removing campaign: " + error, {
              draggablePercent: 60,
              className: 'notify-error',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
          })
      }).catch(function(error) {
            console.error("Error removing campaign: ", error);
            toast("🤖 Error removing campaign: " + error, {
              draggablePercent: 60,
              className: 'notify-error',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
        });
      
        //if the deleted item is a user
    } else if (this.props.type == "user") {

      db.collection("user").doc(this.props.id).delete().then(function() {
      
      toast("🤖 user deleted!", {
        draggablePercent: 60,
        className: 'notify-delete',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });


        }).catch(function(error) {
            console.error("Error removing user: ", error);
            toast("🤖 Error removing user: " + error, {
              draggablePercent: 60,
              className: 'notify-error',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
        });
        //if the deleted item is a template
    } else if (this.props.type == "template") {

      db.collection("template").doc(this.props.id).delete().then(function() {
      
      toast("🤖 template deleted!", {
        draggablePercent: 60,
        className: 'notify-delete',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });


        }).catch(function(error) {
            console.error("Error removing template: ", error);
            toast("🤖 Error removing template: " + error, {
              draggablePercent: 60,
              className: 'notify-error',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
        });
        //if the deleted item is a utm
    }  else {
    db.collection("utm").doc(this.props.id).delete().then(function() {
    
    toast("🤖 UTM deleted!", {
      draggablePercent: 60,
      className: 'notify-delete',
      bodyClassName: "notify-text",
      progressClassName: 'notificationProgressBar'
      })
    }).then(function(){
      //redirect to campaigns
      const spl = window.location.pathname.split("/")
      if(spl[1] === "campaigns" && spl[3] === "utm") {
        window.location.pathname = '/campaigns'
      } 
      
      }).catch(function(error) {
          console.error("Error removing document: ", error);
          toast("🤖 Error removing campaign: " + error, {
            draggablePercent: 60,
            className: 'notify-error',
            bodyClassName: "notify-text",
            progressClassName: 'notificationProgressBar'
          });
      });
    }
    //Field value does not match 'DELETE'
  } else {
      window.alert("Write 'DELETE' in the field to delete this item")
      toast("🤖 Write 'DELETE' in the field to delete this item.", {
        draggablePercent: 60,
        className: 'notify-error',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value.toUpperCase(),
      itemId: this.props.id
    });
  }

render() {
  
    return(
      <Modal header="" trigger={<Button tooltip={this.props.type ? `Delete ${this.props.type}` : "Delete item"} className="transparent transparentButton grey-text center" id="Delete">
          <Icon className="md-light ">
          delete
          </Icon>
        </Button>}>
          <Row>
            <Col s={10} m={6} l={6} offset="l3 m3 s1">
              {this.props.type == "campaign" ? <h4>Really delete this campaign?</h4> : <h4 className="loginSignupHeader">Really Delete this {this.props.type ? this.props.type : "item"}?</h4>}
              {/* <h4 className="loginSignupHeader">Really Delete this {this.props.type ? this.props.type : "item"}?</h4> */}
              {this.props.type == "campaign" ? <p>And all UTMs and templates associated with this campaign</p> : ''}
                  <Input type="text" label="Type 'DELETE'" id={`delete${this.props.id}`} name="delete" s={12} value={this.state.delete.toUpperCase()} onChange={this.handleChange}/>

                <Row>
                  <Col s={6} m={6} l={6} >
                    <Button className="primary modal-action modal-close" id="deleteItem" onClick={this.deleteItem}>
                      Delete {this.props.type ? this.props.type : "" }

                    </Button>
                  </Col>
                  </Row>
            </Col>
          </Row>
        </Modal>
    )
  }
}

export default DeleteItem
