import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row, Table} from 'react-materialize';
import './../ComponentCSS.css';
import UtmActions from './UtmActions';
import { Link } from 'react-router-dom';

function ActionCard(props) {

  return(

    <Col className="actionCard">
      <h3>{props.length}</h3>
      {props.type == "campaign" ? <h5>{props.length !== 1 ? "CAMPAIGNS" : props.type.toUpperCase()}</h5> : ''}
      {props.type == "utm" ? <h5>{props.length !== 1 ? "UTMS" : props.type.toUpperCase()}</h5> : ''}
      {props.type == "user" ? <h5>{props.length !== 1 ? "USERS" : props.type.toUpperCase()}</h5> : ''}
    </Col>

  )
}

export default ActionCard
