import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row} from 'react-materialize';
import './ComponentCSS.css'

function CampaignItem(props) {

  const link = props.data.fullUtm


  if(props.data !== "createNew") {


  return(
    
      <Col onClick={props.onClick} l={6} m={6} s={12}>
        <Card key={props.data.id} data-data={props.data.data.campaign} className='white darken-1 utmCard' textClassName='grey-text' title={props.data.data.campaignName} actions={[<a href={props.data.fullUtm}>{props.data.data.teamName}</a>]}>

        </Card>
      </Col>
    

  )
} else {

  return(

    <Col l={2} m={6} s={12}>
      <Card className='blue-grey darken-1' id={props.data} textClassName='white-text' actions={[<a href="#"></a>]}>

        <Icon className="createNewIcon">add</Icon>
        <p>start new campaign</p>

      </Card>
    </Col>

  )

}
}

export default CampaignItem
