import React, {Component} from 'react';
import './ComponentCSS.css';
import SubNavItems from './SubNavItems'
import utmList from './data.js'
import SubNavHeader from './components/SubNavHeader';
import { Campaigns } from './providers/Campaigns';
import CampaignMenuItem from './components/CampaignMenuItem';
import { Button, Card, Row, Col, Input, Icon } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import * as firebase from 'firebase';
import fire from './fire';

class SubNav extends Component {
  constructor(props) {
    super()
    this.handleFilter = this.handleFilter.bind(this);
      this.state = {
          location: "undefined",
          subNavItems: [],
          filter: ''
      }
    }

    handleFilter(e) {
      this.setState({
        filter: e.target.value
      });
    }

  componentWillMount() {
      this.setState({
        location: this.props.location,

      })


  }

      render() {
        // here comes js
        // const data = this.props.data;
        // const utmData = data.map(utm => {
        //   return (
        //     <SubNavItems item={utm.campaign} />
        //   )
        // })


          //<SubNavItems header={true} item={this.state.subNavItems.dashboard.header}/>
          //{utmData}
        return(
          <Campaigns.Consumer>{(dataSet) => {

            return (
              <div className="SubNav">
                <div className="subNavHeader">

                    <SubNavHeader data={dataSet}/>

                </div>

                <Row>

                  <Col s={10} m={10} l={10} offset="l1 m1 s1" className="">
                    <Input className="greyColor searchBar" s={12} label="" placeholder="Search" onChange={this.handleFilter} ><Icon className="md-dark greyColor md-inactive">search</Icon></Input>
                  </Col>
                </Row>

                <div className="SubNavMenu">

                {dataSet.map(campaign => {
                  const campaignId = campaign.id;
                  const campaignData = campaign.data;
                  const link = `/campaigns/${campaignId}`;
                  
                  let countUTMS = []
                  
                  this.props.items.map(function(utm) {
                    if (utm.data.campaignId == campaignId) {
                      countUTMS.push(utm.data.campaignId)
                      console.log(countUTMS)
                    }
                  })

                  if(!this.props.personalMode) {

                      if(this.state.filter == "") {

                      return(
                        <NavLink activeClassName="activeSubMenuItem" to={link}>
                          <Row className="SubMenuItem">
                            <CampaignMenuItem UTMCount={countUTMS.length} data={campaignData} id={campaignId}/>
                          </Row>
                        </NavLink>
                      )
                    } else {
                        if (campaignData.campaignName.includes(this.state.filter)) {
                          return(
                            <NavLink activeClassName="activeSubMenuItem" to={link}>
                              <Row className="SubMenuItem">
                                <CampaignMenuItem UTMCount={countUTMS.length} data={campaignData} id={campaignId}/>
                              </Row>
                            </NavLink>
                          )
                        }
                    }
                  } else {
                    if(this.state.filter == "" && campaignData.creatorId == fire.auth().currentUser.uid) {

                      return(
                        <NavLink activeClassName="activeSubMenuItem" to={link}>
                          <Row className="SubMenuItem">
                            <CampaignMenuItem UTMCount={countUTMS.length} data={campaignData} id={campaignId}/>
                          </Row>
                        </NavLink>
                      )
                    } else if(campaignData.campaignName.includes(this.state.filter) && campaignData.creatorId == fire.auth().currentUser.uid) {
                        
                          return(
                            <NavLink activeClassName="activeSubMenuItem" to={link}>
                              <Row className="SubMenuItem">
                                <CampaignMenuItem UTMCount={countUTMS.length} data={campaignData} id={campaignId}/>
                              </Row>
                            </NavLink>
                          )                        
                    }
                  }
                })
              }
                </div>



              </div>

            )
          }
        }</Campaigns.Consumer>
      );
    }
  }

    export default SubNav
