import React, {Component} from 'react';
import './ComponentCSS.css'


class SubNavItems extends Component {

  render() {
    //here comes js


    return(

      <div className="SubNavItems" >
          <div className={this.props.header == true ? "SubNavItem-single-header" : "SubNavItem-single"}>
            <a href="#">{this.props.item}</a>
          </div>

      </div>

    )
  }
}

export default SubNavItems
