import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Autocomplete, Row, Col, Chip, Table, Input, Modal, Icon, Toast, Checkbox} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { toast } from 'react-toastify';
import {SuccessPhrase} from './SuccessMessages';
import ProfilePic from '../ProfilePic';
import unknown from '../images/unknown.png'
import {Data} from '../providers/data';
import SubNav from '../SubNav';
import CampaignItem from './../CampaignItem';
import { NavLink } from 'react-router-dom';

class CampaignsOverview extends Component {
    constructor() {
        super()
        this.state = {
        filter: ''
        }
    }

    componentDidMount() {
        console.log('campaigns mounted')
    }

    render() {
        return (
            <Data.Consumer>{(dataSet) => {
        return (
            <div className="MainContent">

                {window.innerWidth > 1300 ? <SubNav personalMode={this.props.personalMode} items={dataSet}/> : ''}
                
            
            <Row className="noMargin fullWidth">
                <div className="headerBlock dashboardHeader primary paddingThirty">

                    <Col s={12} m={6} l={6} offset="l3 m3">
                        <h1 className="noMargin"><strong>Your campaigns</strong></h1>
                    </Col>
                </div>
                <Row>
                <Col className="" xl={6} l={6} m={12} s={12} offset="xl3 l3">
                    {this.props.campaigns.map(campaign => {
                                        const campaignId = campaign.id;
                                        const campaignData = campaign.data;
                                        const link = `/campaigns/${campaignId}`;
                                        console.log(link);
                                        if(!this.props.personalMode) {
                                            //personal mode off    
                                            return (
                                                <NavLink to={link}>
                                                    <CampaignItem data={campaign} key={campaign.id} />
                                                </NavLink>
                                            )
                                            } else if(campaignData.creatorId == fire.auth().currentUser.uid) {
                                                //personal mode is on. return personal campaigns only
                                                return(
                                                    <NavLink to={link}>
                                                        <CampaignItem data={campaign} key={campaign.id} />
                                                    </NavLink>
                                                )
                                            }
                                        
                                        // return (
                                        //     <tr> 
                                        //         <td>
                                        //         <img className="ProfileImageSmall" src={this.props.userInfo.profilePicture ? this.props.userInfo.profilePicture : unknown } />
                                        //         </td>
                                                
                                        //         <td>{campaignData.url}</td> 
                                        //         <td>{campaignData.source}</td>
                                        //         <td>{campaignData.medium}</td>
                                        //         <td>{campaignData.campaign}</td>
                                        //         <td>{campaignData.term}</td>
                                        //         <td>{campaignData.content}</td>
                                        //     </tr>
                                        // )
                                    })}
                </Col>
                </Row>
                
                {/* <Row> 
                    <Col className="secundary" xl={12} l={12} m={12} s={12} >
                        <Table hoverable={true} responsive={true}>
                        <   thead className="secondary">
                                <tr >
                                  
                                  <th className="grey-text" data-field="creator">Creator</th>
                                  <th className="grey-text" data-field="url">URL</th>
                                  <th className="grey-text" data-field="source">Source</th>
                                  <th className="grey-text" data-field="medium">Medium</th>
                                  <th className="grey-text" data-field="role">Campaign</th>
                                  <th className="grey-text" data-field="term">Term</th>
                                  <th className="grey-text" data-field="content">Content</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.props.data.map(campaign => {
                                    const campaignId = campaign.id;
                                    const campaignData = campaign.data;
                                    const link = `/campaigns/${campaign.data.campaignId}/utm/${campaign.id}`;
                                    console.log(link);
                                    return (
                                        <tr> 
                                            <td>
                                            <img className="ProfileImageSmall" src={this.props.userInfo.profilePicture ? this.props.userInfo.profilePicture : unknown } />
                                            </td>
                                            
                                            <td>{campaignData.url}</td> 
                                            <td>{campaignData.source}</td>
                                            <td>{campaignData.medium}</td>
                                            <td>{campaignData.campaign}</td>
                                            <td>{campaignData.term}</td>
                                            <td>{campaignData.content}</td>
                                        </tr>
                                    )
                                })}

                              </tbody>  
                            

                        </Table>
                    </Col>
                </Row> */}
            </Row>
              
            </div>
            )
        }
      }</Data.Consumer>
            )
    }
    
}

export default CampaignsOverview