import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, Icon, Input, Modal} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SocialIcon } from './getSocialIcon';

class TeamSettingsInputField extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleMediumSave = this.handleMediumSave.bind(this)
    this.state = {
      value: '',
      edited: false
    }

  }

//   shouldComponentUpdate(nextProps, nextState) {
//       return false;
//   }

    componentDidMount() {
        this.setState({
            value: this.props.value,
            edited: false
            })
    }

    handleChange(e) {
        e.preventDefault()
        this.setState({
            // value: e.target.value
            value: e.target.value,
            edited: e.target.value === this.props.value ? false : true
        })
    }

    handleSave = () => {
        const stateSource = this.props.sources ? this.props.sources : '';
        const sourcePlaceholder = []; 
        const db = firebase.firestore();
  
        for (let [key, value] of Object.entries(stateSource)) {
          
          if (this.props.index == key) {
            
            sourcePlaceholder.push(this.state.value)
          } else {
            sourcePlaceholder.push(value)
          }
          if(sourcePlaceholder.length === stateSource.length) {
            
            
            db.collection("team").doc(this.props.teamId).update({
                sources: firebase.firestore.FieldValue.arrayUnion(this.state.value)
              })
            
            db.collection("team").doc(this.props.teamId).update({
            sources: firebase.firestore.FieldValue.arrayRemove(this.props.value)
            }).then(() => {
                
                this.setState({
                    edited: false
                })
            }).then(() => {
                this.props.updateParent(this.props.index,this.state.value, "sources")
            })  
          }
          
        }
      };

      handleMediumSave = () => {
        const mediumList = this.props.sources ? this.props.sources : '';
        const sourcePlaceholder = []; 
        const db = firebase.firestore();
  
        for (let [key, value] of Object.entries(mediumList)) {
          
          if (this.props.index == key) {
            
            sourcePlaceholder.push(this.state.value)
          } else {
            sourcePlaceholder.push(value)
          }
          if(sourcePlaceholder.length === mediumList.length) {
            
            
            db.collection("team").doc(this.props.teamId).update({
                mediumTypes: firebase.firestore.FieldValue.arrayUnion(this.state.value)
              })
            
            db.collection("team").doc(this.props.teamId).update({
            mediumTypes: firebase.firestore.FieldValue.arrayRemove(this.props.value)
            }).then(() => {
                
                this.setState({
                    edited: false
                })
            }).then(() => {
                this.props.updateParent(this.props.index,this.state.value, "mediumTypes")
            })  
          }
          
        }
      };

render() {
  
    return(
        <Col key={`${this.props.index}-${this.props.value}`} s={12} m={12} l={12}>
            <Col s={6} m={6} l={6}>
                <Col s={2} m={2} l={2}>
                    {this.props.type == "sources" ? <SocialIcon icon={this.state.value} /> : ''}
                </Col>
                <Col s={10} m={10} l={10}>
                    <Input 
                        s={12} 
                        id={this.props.value} 
                        label={this.props.value}
                        type="text"
                        placeholder={this.props.value}
                        defaultValue={this.props.value}
                        ref={this.input}
                        // value={this.state.value}
                        onChange={this.handleChange}>
                    </Input>
                </Col>
            </Col>
            <Col s={2} m={2} l={2} offset="l2 m2 s2">
            {this.state.edited && this.props.type == "sources" ? <Button className="primary" onClick={this.handleSave}>Save</Button> : ''}
            {this.state.edited && this.props.type == "mediumTypes" ? <Button className="primary" onClick={this.handleMediumSave}>Save</Button> : ''}
            </Col>
        </Col>
    )
  }
}

export default TeamSettingsInputField
