import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, Icon, Input, Tab, Tabs} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import TeamSettingsInputField from './TeamSettingInputField';

class SourceMediumTab extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onUpdateItem = this.onUpdateItem.bind(this)
    this.state = {
      list: ''
    }

  }

    componentDidMount() {
        
        this.setState({
            list: this.props.list
            })
    }

    handleChange(e) {
        e.preventDefault()
        this.setState({
            list: e.target.value
        })
         //this.props.updateParent(this.props.index, e.target.value)
    }

    onUpdateItem = (i, newItem) => {
        const stateList = this.state.list ? this.state.list : '';
        const listPlaceholder = []; 
  
        for (let [key, value] of Object.entries(stateList)) {
          
          if (i == key) {
            
            listPlaceholder.push(newItem)
          } else {
            listPlaceholder.push(value)
          }
          if(listPlaceholder.length === stateList.length) {
            
            this.setState({
              list: listPlaceholder
            })
          }
          
        }
      };

render() {
  
    return(
        <Tabs className='tab-demo tabs-fixed-width z-depth-1' tabOptions={[{swipable: true}, {responsiveThreshold: 1300}]}>
            <Tab active title={this.props.list ? `list (${this.props.list.length})` : "list" }>
                <Row>
                    <Col s={12} m={12} l={12}>
                    
                        <Col s={12} m={12} l={12} >
                            <Col s={12} m={12} l={12} className="left-align">

                                {this.state.list ? this.state.list.map((item, index) => {
                                    
                                    return(
                                    
                                        <TeamSettingsInputField value={item} key={index} index={index} updateParent={this.onUpdateItem}/>
                                    )
                                }) : ''
                                }
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Tab>
        </Tabs>
    )
  }
}

export default SourceMediumTab