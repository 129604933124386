import React, {Component} from 'react';
import { Button, Card, Row, Col, Icon, ProgressBar, Input} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import CreateCampaignModal from './createCampaignModal';

function SubNavHeader(props) {

  return(
          <Row>

            <Col className="subNavContent" s={12} m={12} l={12}>
              <Col s={6} m={6} l={6}>
                  <p className="white-text">Campaigns</p>
              </Col>
              <Col s={6} m={6} l={6}>
                  <p className="white-text">{props.data.length} / unlimited</p>
              </Col>
              <Col s={12} m={12} l={12}>
                <CreateCampaignModal data={props.data}/>
              </Col>
            </Col>
          </Row>
        )
      }

export default SubNavHeader
