import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Icon, Row, Col, ProgressBar, Input, Modal} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

class UpdateCampaignMeta extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.updateMeta = this.updateMeta.bind(this);
    this.state = {
      campaign: '',
      team: '',
      userId: '',
      campaignMetaInput: '',
      campaignMeta: ''
    }

  }

    componentDidMount() {
      
    }

  updateMeta(e) {
    const db = firebase.firestore();
    db.collection('campaign').doc(this.props.campaignId).update({
          "campaignMeta": this.state.campaignMetaInput
        })
      .then(function() {
        toast("Campaign information updated 🙌", {
          draggablePercent: 60,
          className: 'notify-success',
          bodyClassName: "notify-text",
          progressClassName: 'notificationProgressBar'
        })
      })
      .catch(function(error) {
        
        toast("Something went wrong 🧐", {
          draggablePercent: 60,
          className: 'notify-error',
          bodyClassName: "notify-errortext",
          progressClassName: 'notificationProgressBar'
        })
    });
  }

  handleChange(e) {

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSwitch(e) {
    this.setState({
      forceLowerCase: !this.state.forceLowerCase
    })
  }


render() {

    return(
    <Modal header="" trigger={<Button tooltip="Add description" className="ghostButtonNoBorder"><Icon>edit</Icon></Button>}>

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Add or change your description</h4>
                <Input type="text" label="Campaign description" name="campaignMetaInput" defaultValue={this.props.campaignId} s={12} value={this.state.campaignMetaInput} onChange={this.handleChange}/>

            <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary modal-action modal-close" id="createCampaign" onClick={this.updateMeta}>
                    Update
                  </Button>
                </Col>
            </Row>
          </Col>
    </Modal>
    )
  }
}

export default UpdateCampaignMeta
