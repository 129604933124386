import React, { Component } from "react";
import './MainContent.css';
import './ComponentCSS.css';
import UtmItem from './UtmItem';
import {Data} from './providers/data';
import { Campaigns } from './providers/Campaigns';
import { UserInfo } from './providers/userinfo';
import { Button, Card, Row, Col, Input, Icon, Table, Breadcrumb, MenuItem } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './CampaignItem';
import SubNav from './SubNav';
import * as firebase from 'firebase';
import fire from './fire';
import Builder from './components/builder';
import BuilderModal from './components/builderModal';
import DeleteItem from './components/deleteItem';
import UtmListItem from './components/UtmListItem';
import { toast } from 'react-toastify';
import UpdateCampaignMeta from './components/updateCampaignMeta';
import unknown from './images/unknown.png'
import CopyText from './components/CopyText';
import { createReadStream } from "fs";

class UtmOverview extends Component {
  constructor(props) {
    super(props)
    this.handleFilter = this.handleFilter.bind(this);
    this.getCampaignInfo = this.getCampaignInfo.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleBuilder = this.handleBuilder.bind(this);
    
    this.handleEditState = this.handleEditState.bind(this);
      this.state = {
        filter: '',
        location: '',
        builder: false,
        editing: false,
        campaignInfo: null,
        campaignName: '',
        campaignMeta: '',
        campaignList: null,
        builderActive: false,
        campaignMetaInput: '',
        campaignMeta: ''
      }
    }

    

    handleFilter(e) {
      this.setState({
        filter: e.target.value
      });
    }
    getCampaignInfo() {
      const campaignId = this.props.routeProps.match.params.id
      const db = firebase.firestore();
      var info = '';
      db.collection('campaign').doc(campaignId)
      .onSnapshot((querySnapshot) => {
       var campaignInfo = [];
       const teamRef = querySnapshot.data().teamRef;

       db.collection('campaign').where("teamRef", "==", teamRef)
       .onSnapshot((querySnapshot) => {
        var campaignList = [];
        querySnapshot.forEach(function(campaign) {
          

          campaignList.push({
            id: campaign.id,
            data: campaign.data()
          });
        });
      //
      this.setState({
        campaignList: campaignList
          })
        })
       })
    }

  handleState(value) {
    this.setState({
      campaignInfo: value
    })
  }

  handleBuilder(e) {
    
    this.setState({
      builderActive: !this.state.builderActive
    });
  }

  componentDidMount() {
    this.getCampaignInfo()
    this.setState({
      location: this.props.routeProps.match.params.id
    })
  }

  handleEditState(e) {
    this.setState({
      editing: !this.state.editing
    })
  }

  render() {
    
    return (
      <Data.Consumer>{(dataSet) => {
        const campaignObject = [];
        let correctCampaign = '';
        let rightCampaign = {};
        const utms = [];
        this.props.campaigns.map(utm => {
          if (utm.data.campaignId == this.props.routeProps.match.params.id) {
            campaignObject.push(utm.data.campaign)
            utms.push(utm.data)
            
          }
          let campaignObj = this.state.campaignList ? this.state.campaignList.find(campaignObj => campaignObj.id == this.props.routeProps.match.params.id) : "waiting" ;
          
          correctCampaign = this.state.campaignList ? campaignObj.data.campaignName : "waiting";
          rightCampaign = campaignObj;
          
        })

      return (
            <div className="MainContent">

                {window.innerWidth > 1300 ? <SubNav personalMode={this.props.personalMode} items={dataSet}/> : ''}

                <div className="MainContent-header">

                  <div className="mainContentUtm">
                  <BuilderModal campaign={correctCampaign} campaignId={this.props.routeProps.match.params.id} teamRef={this.state.campaignList} userInfo={this.props.userInfo} user={this.props.user} campaigns={this.props.campaigns} sources={this.props.team.sources} mediumTypes={this.props.team.mediumTypes}/>
                    <Row>
                    <Breadcrumb className="transparentBlock">
                      <MenuItem >
                        <NavLink className="grey-text greyArrow" to={'/campaigns'}>Campaigns</NavLink>
                        {/* <a className="grey-text greyArrow" href="/campaigns">Campaigns</a>  */}
                      </MenuItem>
                      <MenuItem className="greyArrow"><span className="grey-text greyArrow">{correctCampaign}</span></MenuItem>
                    </Breadcrumb>
                    {rightCampaign.data ? <p className="grey-text">{rightCampaign.data.campaignMeta}</p> : ''}
                    </Row>
                    <Row>

                      <Col s={8} m={8} l={8} offset="l2 m2 s2" className="searchbar">
                        <Col s={2} m={2} l={2} offset="l4 m4 s4">
                          <UpdateCampaignMeta campaignId={this.props.routeProps.match.params.id} />
                        </Col>
                        <Col s={2} m={2} l={2}>
                          <DeleteItem type="campaign" id={this.props.routeProps.match.params.id}/>                      
                        </Col>
                      </Col>
                      <Col s={8} m={8} l={8} offset="l2 m2 s2" className="searchbar">
                          <Input s={12} label="" placeholder="Search" onChange={this.handleFilter} ><Icon className="greyColor">search</Icon></Input>
                          {/* <Button floating fab='horizontal' icon='add' className='primary' large style={{bottom: '45px', right: '24px'}}>
                          </Button> */}
                        </Col>
                    </Row>
                    <Row> 
                    <Col className="secundary" xl={12} l={12} m={12} s={12} >
                        {this.props.data.length > 0 ? <Table hoverable={true} responsive={true}>
                          <thead className="secondary">
                                <tr >
                                  
                                  <th className="grey-text" data-field="creator">Creator</th>
                                  <th className="grey-text" data-field="url">URL</th>
                                  <th className="grey-text" data-field="source">Source</th>
                                  <th className="grey-text" data-field="medium">Medium</th>
                                  <th className="grey-text" data-field="role">Campaign</th>
                                  <th className="grey-text" data-field="term">Term</th>
                                  <th className="grey-text" data-field="content">Content</th>
                                  <th className="grey-text" data-field="copy">Copy/Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.props.data.map(utm => {
                                    const utmId = utm.id;
                                    const utmData = utm.data;
                                    const link = `/campaigns/${utmData.campaignId}/utm/${utmId}`;
                                    // const link = `/campaigns/${this.state.location}/utm/${utm.id}`;
                                    const cam = utm.data.campaignId;
                                    const utmRef = this.props.routeProps.match.params.id;

                                    let creator = ''
                                    let creators = []
                                  //let creator = this.props.teamMembers.find(creator => creator.id == utmData.creator);
                                  //console.log(creator, creator.data)
                                  this.props.teamMembers.forEach(function(user) {
                                    
                                      creators.push({
                                        userData: user.data,
                                        userId: user.id
                                      })

                                      if(user.id == utmData.creator) {
                                        creator = user.data.profilePicture
                                      }
                                  })
                                  console.log(creators, creators.length)
                                  // let creator = creators.find(creators.userId == utmData.creator);
                                  // let creatorProfile = creator.data.profilePicture

                                  if(!this.props.personalMode) {
                                    if(cam == utmRef && this.state.filter == "") {
                                    return (
                                        <tr> 
                                            <td>
                                            <img className="ProfileImageSmall" src={creator ? creator : unknown } />
                                            </td>
                                            
                                            <td>{utmData.url}</td> 
                                            <td>{utmData.source}</td>
                                            <td>{utmData.medium}</td>
                                            <td>{utmData.campaign}</td>
                                            <td>{utmData.term}</td>
                                            <td>{utmData.content}</td>
                                            <td>
                                              <Col s={12} m={6} l={6}>
                                              {/* <Button tooltip="Copy UTM" className="ghostButtonNoBorder"><Icon>file_copy</Icon></Button> */}
                                                <CopyText text={utmData.fullUTM} class="ghostButtonNoBorder" action={this.handleCopy} />
                                              </Col>
                                              <Col s={12} m={6} l={6}>
                                                {/* <Button tooltip="Delete UTM" className="ghostButtonNoBorder redHover"><Icon>delete</Icon></Button> */}
                                                <DeleteItem id={utmId}/>
                                              </Col>
                                            </td>
                                        </tr>
                                    )
                                  } else {
                                    if(cam == utmRef & this.state.filter != "") {
                                      const name = utmData.fullUTM.toUpperCase();;
                                      const query = this.state.filter.toUpperCase();
                                      if (name.includes(query)) {
                                        return (
                                          // <UtmListItem data={utmData} creator={creator} key={utm.id} link={link} id={utmId} type="list"/>
                                          <tr> 
                                            <td>
                                            <img className="ProfileImageSmall" src={creator ? creator : unknown } />
                                            </td>
                                            
                                            <td>{utmData.url}</td> 
                                            <td>{utmData.source}</td>
                                            <td>{utmData.medium}</td>
                                            <td>{utmData.campaign}</td>
                                            <td>{utmData.term}</td>
                                            <td>{utmData.content}</td>
                                            <td>
                                              <Col s={12} m={4} l={6}>
                                              {/* <Button tooltip="Copy UTM" className="ghostButtonNoBorder"><Icon>file_copy</Icon></Button> */}
                                                <CopyText text={utmData.fullUTM} class="ghostButtonNoBorder" action={this.handleCopy} />
                                              </Col>
                                              <Col s={12} m={4} l={6}>
                                                {/* <Button tooltip="Delete UTM" className="ghostButtonNoBorder redHover"><Icon>delete</Icon></Button> */}
                                                <DeleteItem id={utmId}/>
                                              </Col>
                                            </td>
                                        </tr>
                                        )}
                                      }
                                    }
                                  } else {
                                    if(cam == utmRef && this.state.filter == "" && creator == fire.auth().currentUser.uid) {
                                      return (
                                          <tr> 
                                              <td>
                                              <img className="ProfileImageSmall" src={creator ? creator : unknown } />
                                              </td>
                                              
                                              <td>{utmData.url}</td> 
                                              <td>{utmData.source}</td>
                                              <td>{utmData.medium}</td>
                                              <td>{utmData.campaign}</td>
                                              <td>{utmData.term}</td>
                                              <td>{utmData.content}</td>
                                              <td>
                                                <Col s={12} m={6} l={6}>
                                                {/* <Button tooltip="Copy UTM" className="ghostButtonNoBorder"><Icon>file_copy</Icon></Button> */}
                                                  <CopyText text={utmData.fullUTM} class="ghostButtonNoBorder" action={this.handleCopy} />
                                                </Col>
                                                <Col s={12} m={6} l={6}>
                                                  {/* <Button tooltip="Delete UTM" className="ghostButtonNoBorder redHover"><Icon>delete</Icon></Button> */}
                                                  <DeleteItem id={utmId}/>
                                                </Col>
                                              </td>
                                          </tr>
                                      )
                                    } else {
                                      if(cam == utmRef & this.state.filter != "" && creator == fire.auth().currentUser.uid) {
                                        const name = utmData.fullUTM.toUpperCase();;
                                        const query = this.state.filter.toUpperCase();
                                        if (name.includes(query)) {
                                          return (
                                            // <UtmListItem data={utmData} creator={creator} key={utm.id} link={link} id={utmId} type="list"/>
                                            <tr> 
                                              <td>
                                              <img className="ProfileImageSmall" src={creator ? creator : unknown } />
                                              </td>
                                              
                                              <td>{utmData.url}</td> 
                                              <td>{utmData.source}</td>
                                              <td>{utmData.medium}</td>
                                              <td>{utmData.campaign}</td>
                                              <td>{utmData.term}</td>
                                              <td>{utmData.content}</td>
                                              <td>
                                                <Col s={12} m={4} l={6}>
                                                {/* <Button tooltip="Copy UTM" className="ghostButtonNoBorder"><Icon>file_copy</Icon></Button> */}
                                                  <CopyText text={utmData.fullUTM} class="ghostButtonNoBorder" action={this.handleCopy} />
                                                </Col>
                                                <Col s={12} m={4} l={6}>
                                                  {/* <Button tooltip="Delete UTM" className="ghostButtonNoBorder redHover"><Icon>delete</Icon></Button> */}
                                                  <DeleteItem id={utmId}/>
                                                </Col>
                                              </td>
                                          </tr>
                                          )}
                                        }
                                      }
                                  }

                                })}

                              </tbody>  
                            

                              </Table> : 
                              // <Row><p>No UTMs</p> </Row>
                              <tr> 
                                    <td>...</td>
                                    <td>...</td> 
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>
                                      ...
                                    </td>
                                </tr>
                              }
                    </Col>
                </Row>
                    {/* <Row>
                      <Col s={8} m={8} l={8} offset="l2 m2 s2" className="campaignHeader">
                          <Col s={8} m={8} l={8} offset="l2 m2 s2">
                            <h4>{correctCampaign}</h4>
                            <Col s={8} m={8} l={8} offset="l2 m2 s2">
                                {rightCampaign.data ? <p>{rightCampaign.data.campaignMeta}</p> : ''}
                                <Col s={6} m={6} l={6}>
                                  <UpdateCampaignMeta campaignId={this.props.routeProps.match.params.id} />
                                </Col>
                                <Col s={6} m={6} l={6}>
                                  <DeleteItem type="campaign" id={this.props.routeProps.match.params.id}/>
                                </Col>
                            </Col>
                              
                              <BuilderModal campaign={correctCampaign} campaignId={this.props.routeProps.match.params.id} teamRef={this.state.campaignList} userInfo={this.props.userInfo} user={this.props.user} campaigns={this.props.campaigns} sources={this.props.team.sources} mediumTypes={this.props.team.mediumTypes}/>

                          </Col>
                         
                      </Col>
                      {this.props.user && this.state.builderActive ? <Builder userInfo={this.props.userInfo} user={this.props.user} campaigns={this.props.campaigns} sources={this.props.team.sources} mediumTypes={this.props.team.mediumTypes}/> : ''}

                    </Row> */}

                  </div>

                  {/* <div className="utmGrid"> */}
                  {/* <Row>
                    <Col s={8} m={8} l={8} offset="l2 m2 s2" className="utmStats" >
                      <Row>
                          <Col s={12} m={12} l={6} >
                            <h6>Clicks</h6>
                            <h3>101</h3>
                          </Col>
                          <Col s={12} m={12} l={6} >
                            <h6>Pages</h6>
                            <h3>303</h3>
                          </Col>
                          <Col s={12} m={12} l={6} >
                            <h6>Pages/Session</h6>
                            <h3>3</h3>
                          </Col>
                          <Col s={12} m={12} l={6} >
                            <h6>Avg. Session duration</h6>
                            <h3>0:03:21</h3>
                          </Col>
                      </Row>
                      </Col>
                    </Row> */}

                  {/* <Row>
                    <Col s={8} m={8} l={8} offset="l2 m2 s2">
                      {this.props.data.map(utm => {
                        const utmId = utm.id;
                        const utmData = utm.data;
                        const link = `/campaigns/${this.state.location}/utm/${utm.id}`;
                        const cam = utm.data.campaignId;
                        const utmRef = this.props.routeProps.match.params.id;

                        let creator = this.props.teamMembers.find(creator => creator.id == utmData.creator);

                        if(cam == utmRef && this.state.filter == "") {
                          return (
                              
                              <UtmListItem data={utmData} creator={creator} key={utm.id} link={link}  id={utmId} type="list"/>
                              )
                            } else {
                                if(cam == utmRef & this.state.filter != "") {
                                  const name = utmData.fullUTM.toUpperCase();;
                                  const query = this.state.filter.toUpperCase();
                                  if (name.includes(query)) {
                                    return (
                                      <UtmListItem data={utmData} creator={creator} key={utm.id} link={link} id={utmId} type="list"/>
                                    )}
                                  }
                                }
                              })}
                        </Col>
                      </Row> */}
                  {/* </div> */}
                </div>


              </div>
          )
    }
  }</Data.Consumer>
    );
  }
}


export default UtmOverview;
