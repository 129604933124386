import React, { Component } from "react";
import { GoogleProvider, GoogleDataChart } from 'react-analytics-widget'
 
class AnalyticsAPI extends Component {
    constructor() {
      super()

      this.state = {
        viewId: '',
        clientId: '1090337638249-mhf6hhsg54pmnkp0mq6qclq9mbaou4hl.apps.googleusercontent.com'
      }
    }

render() {
 
        // graph 1 config
        const last30days = {
        reportType: "ga",
        query: {
            dimensions: "ga:date",
            metrics: "ga:pageviews",
            "start-date": "30daysAgo",
            "end-date": "yesterday"
        },
        chart: {
            type: "LINE",
            options: {
            // options for google charts
            // https://google-developers.appspot.com/chart/interactive/docs/gallery
            title: "Last 30 days pageviews"
            }
        }
        }
        
        // graph 2 config
        const last7days = {
        reportType: "ga",
        query: {
            dimensions: "ga:date",
            metrics: "ga:pageviews",
            "start-date": "7daysAgo",
            "end-date": "yesterday"
        },
        chart: {
            type: "LINE"
        }
        }
        
        // analytics views ID
        const views = {
        query: {
            ids: "ga:71794823"
            }
        }

    return(
        
        <GoogleProvider clientId={this.state.clientId}>
            
            <GoogleDataChart views={views} config={last30days} />
            <GoogleDataChart views={views} config={last7days} />
        </GoogleProvider>
        )
    }
}

export default AnalyticsAPI