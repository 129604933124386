import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row, Table} from 'react-materialize';
import './ComponentCSS.css';
import UtmActions from './components/UtmActions';
import { NavLink } from 'react-router-dom';

function UtmItem(props) {

  const link = props.data.fullUtm
  

  if(props.data !== "createNew") {


  return(

    <Col onClick={props.onClick} xl={3} l={4} m={12} s={12}>
      <a href={link}>
      <Card data-data={props.data.data.campaign} className='white darken-1 utmCard left-align' textClassName='grey-text' title={`${props.data.data.source}/${props.data.data.medium}`} actions={[<UtmActions editLink={props.link} data={props.data.data.fullUTM} id={props.data.data.id || props.id} />]}>

                <p>URL: {props.data.data.url}</p>
                <p>{props.data.data.term ? "" : "Term: " + props.data.data.term}</p>
                <p>{props.data.data.content ? "" : "Content: " + props.data.data.content}</p>

      </Card>
      </a>

    </Col>

  )
} else {

  return(

    <Col onClick={props.onClick} l={12} m={12} s={12} >
      <Card className='transparent darken-1' id={props.data} textClassName='createNewText' onClick={props.handleClick}>

        <Icon className="createNewIcon">{props.icon}</Icon>
        <p className="createNewText">{props.text ? props.text : "Create a new UTM"}</p>

      </Card>
    </Col>

  )

}
}

export default UtmItem
