import React, { Component } from "react";
import './MainContent.css';
import './ComponentCSS.css';
import UtmItem from './UtmItem';
import {Data} from './providers/data';
import { Campaigns } from './providers/Campaigns';
import { Button, Card, Row, Col } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './CampaignItem';
import SubNav from './SubNav';
import DeleteItem from './components/deleteItem';

class MainContent extends Component {

  render() {

    return (
      <Data.Consumer>{(dataSet) => {

      return (
            <div className="MainContent">

              <SubNav items={dataSet}/>

                <div className="MainContent-header">

                  <div className="mainContentUtm">
                      <h2>All UTM's :)</h2>
                  </div>

                  <div className="utmGrid">
                    <Row>
                      {dataSet.map(campaign => {
                        const campaignId = campaign.id;
                        const campaignData = campaign.data;
                        const link = `/campaigns/${campaign.data.campaignId}/utm/${campaign.id}`;
                        
                        return (
                            <UtmItem data={campaign} key={campaign.id} link={link} id={campaign.id}/>
                        )
                      })}

                    </Row>
                  </div>
                </div>


              </div>
          )
    }
  }</Data.Consumer>
    );
  }
}

export default MainContent;
