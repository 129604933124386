import React, {Component} from 'react';

  const successPhrases = [
    "Yes, UTM created successfully",
    "You're amazing! Keep on creating UTMs",
    "Another UTM created. Good job!",
    "Saved! Always Be Tracking",
    "UTM Saved - A small step for a marketer, but a huge step for marketing"
]

export const SuccessPhrase = successPhrases[Math.floor((Math.random() * successPhrases.length) + 1)];