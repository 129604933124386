import React, { Component } from "react";
import './../MainContent.css';
import './../ComponentCSS.css';
import UtmItem from './../UtmItem';
import {Data} from './../providers/data';
import { Campaigns } from './../providers/Campaigns';
import { Button, Card, Row, Col, Input, Icon, SideNav, SideNavItem, Table } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './../CampaignItem';
import SubNav from './../SubNav';
import ActionCard from './ActionCard';
import Builder from './builder';
import Notify from './notify'
import BuilderModal from './builderModal';
import UtmListItem from './UtmListItem';
import AnalyticsAPI, {Example} from './AnalyticsAPI';
import fire from "../fire";

class Dashboards extends Component {
  constructor() {
    super()
    this.setProps = this.setProps.bind(this)
    this.handleBuilder = this.handleBuilder.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.state = {
      filter: '',
      userInfo: null,
      builderActive: false,
      personalMode: ""
    }
  }

  componentWillMount() {
    this.setProps()
  }

  setProps() {
    this.setState({
      userInfo: this.props.userInfo,
      personalMode: this.props.personalMode

    })
  }
  handleFilter(e) {
    this.setState({
      filter: e.target.value
    });
  }

  handleBuilder(e) {
    
    this.setState({
      builderActive: !this.state.builderActive
    });
  }

  render() {
    const sortedUTMList = [...this.props.data].sort(); // new instance of baz array is created and sorted
    sortedUTMList.slice(0, 3)
    

    return (
      <Campaigns.Consumer>{(dataSet) => {

      return (
            <div className="dashboardMain">

                <div className="dashboardHeader primary">


                      <Row>

                        {/* <Col s={8} m={8} l={8} offset="l2 m2 s2" className="searchbar">
                          <Input s={12} label="" className="grey-text active-color" defaultValue="" onChange={this.handleFilter} placeholder="search" ><Icon className="md-dark md-inactive">search</Icon></Input>
                        </Col> */}
                      </Row>
                        <Col s={6} m={6} l={6} offset="l3 m3 s3">
                          <h1><strong>Build and manage your UTMs</strong></h1>
                        </Col>
                      <Row className="noBottomMargin">
                        <Col s={8} m={8} l={6} offset="l3 m2 s2">
                          <Col s={6} m={6} l={4}>
                            <ActionCard type="utm" length={this.props.data.length} />
                          </Col>
                          <Col s={6} m={6} l={4}>
                            <ActionCard type="campaign" length={this.props.campaigns.length} />
                          </Col>
                          <Col s={12} m={6} l={4}>
                            <ActionCard type="user" length={this.props.teamMembers.length} />
                          </Col>
                        </Col>
                        {/* //builder  */}
                        {this.props.user && this.state.builderActive ? <Builder userInfo={this.props.userInfo} user={this.props.user} campaigns={this.props.campaigns} sources={this.props.team.sources} mediumTypes={this.props.team.mediumTypes} handleBuilder={this.handleBuilder} team={this.props.team} templateList={this.props.templateList} /> : ''}
                        
                      </Row>

                </div>
                  <div className="">
                    <Row>
                      <Col s={10} m={8} l={8} offset="l2 m2 s1" >
                        <Col s={12} m={12} l={6}  className="searchbar" >
                      {dataSet.length > 0 ? <h5 className="dark-text">Your UTMs for {this.props.userInfo.team}</h5> : <h5>No UTMs created.</h5> }
                      
                      {this.state.builderActive ? <UtmItem data="createNew" icon="close" onClick={this.handleBuilder} text={"Close Builder"} /> : <UtmItem data="createNew" icon="add" onClick={this.handleBuilder} text={dataSet.length == 0 ? "Create your first UTM" : "Create a new UTM"} /> }
                      
                        {this.props.data.slice(0,5).map(utm => {
                          
                          const utmId = utm.id;
                          const utmData = utm.data;
                          const utmCreator = utmData.creator;
                          const link = `/campaigns/${utmData.campaignId}/utm/${utm.id}`;
                          
                          let creator = this.props.teamMembers.find(creator => creator.id == utmData.creator);
                          console.log(creator)
                          if(this.props.personalMode == true) {
                            
                            if(this.state.filter == "" && utmCreator == fire.auth().currentUser.uid) {
                              return (
                                  <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>
                              )
                            } else if(utmCreator == fire.auth().currentUser.uid) {
                              const name = utmData.fullUTM.toUpperCase();;
                              const query = this.state.filter.toUpperCase();;
                              if (name.includes(query)) {
                                return (

                                  <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>

                                )
                              }
                            }

                          } else {

                            if(this.state.filter == "") {
                              return (
                                  <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>
                              )
                            } else {
                              const name = utmData.fullUTM.toUpperCase();;
                              const query = this.state.filter.toUpperCase();;
                              if (name.includes(query)) {
                                return (

                                  <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>

                                )
                              }
                            }
                        }
                        })}

                      </Col>



                      <Col s={12} m={12} l={6} className="searchbar">
                      {dataSet.length > 0 ? <h5 className="dark-text">Your campaigns for {this.props.userInfo.team}</h5> : <h5>No campaigns yet</h5> }
                        {dataSet.slice(0,5).map(campaign => {
                          const campaignId = campaign.id;
                          const campaignData = campaign.data;
                          const link = `/campaigns/${campaign.id}`;
                          const campaignCreator = campaignData.creatorId;

                          let findCampaignCreator = this.props.teamMembers.find(creator => creator.id == campaignCreator);
                          
                          
                          if(this.props.personalMode == true) {
                            //personal mode is true
                              if(this.state.filter == "" && campaignCreator == fire.auth().currentUser.uid) {
                                //no filter, return every personal campaign
                                return (
                                  <NavLink to={link}>
                                    <CampaignItem data={campaign} key={campaign.id}/>
                                  </NavLink>
                                )
                                //if filter is on, return every personal campaign with filter enabled
                              } else if (campaignCreator == fire.auth().currentUser.uid) {
                                const name = campaignData.campaignName.toUpperCase();;
                                const query = this.state.filter.toUpperCase();;
                                if (name.includes(query)) {
                                  return (
                                  <NavLink to={link}>
                                    <CampaignItem data={campaign} key={campaign.id}/>
                                  </NavLink>
                                  )
                              }
                          }
                        } else {
                          if(this.state.filter == "") {
                            //no filter, return every personal campaign
                            return (
                              <NavLink to={link}>
                                <CampaignItem data={campaign} key={campaign.id}/>
                              </NavLink>
                            )
                            //if filter is on, return every personal campaign with filter enabled
                          } else {
                            const name = campaignData.campaignName.toUpperCase();;
                            const query = this.state.filter.toUpperCase();;
                            if (name.includes(query)) {
                              return (
                              <NavLink to={link}>
                                <CampaignItem data={campaign} key={campaign.id}/>
                              </NavLink>
                              )
                          }
                      }
                        }

                        })}
                      </Col>
                        <Col s={12} m={12} l={6} className="searchbar">
                          <AnalyticsAPI />
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </div>
          )
    }
  }</Campaigns.Consumer>
    );
  }
}

export default Dashboards;
