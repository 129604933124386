import React, {Component} from 'react';
import logo from './logo.svg';
import { Button, Card, Row, Col, ProgressBar, Input, Tabs, Tab} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './fire';
import ComponentCSS from './ComponentCSS.css';
import { NavLink } from 'react-router-dom';

class CreateAccount extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.state = {
      email: '',
      emailVerified: '',
      password: '',
      firstName: '',
      lastName: '',
      displayName: '',
      team: ''
    }
  }

  handleSignup(e) {
    e.preventDefault();
    fire.auth().createUserWithEmailAndPassword(this.state.emailVerified, this.state.password)
    .then((u) => {
      
      const user = u.user.uid
      
      const db = firebase.firestore();
              // get team name, then create a new record with random id
              // set teamId, teamName, userLimit (3), users (array with 0 being the current user)
              db.collection("team").add({
                
              }).then((docRef) => {
                  //set user document here
                  
                    
                      db.collection("user").doc(user).set({
                        teamRef: db.collection("team").doc(docRef.id),
                        team: this.state.team,
                        email: this.state.emailVerified,
                        role: 'Admin',
                        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                        personalMode: false
                      })

                      db.collection("team").doc(docRef.id).set({
                        teamId: docRef.id,
                        campaignLimits: 5,
                        teamName: this.state.team,
                        userLimit: 3,
                        users: [user],
                        sources: ['bing','yahoo','google','facebook','youtube','whatsapp','messenger','instagram','tik tok','twitter','reddit','linkedin','snapchat','telegram','medium','quora'],
                        mediumTypes: ['referral','social','cpc','email','display','affiliates'],
                        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                        plan: "basic"
                    })
                  .then(() => {
                    var user = fire.auth().currentUser;

                        user.sendEmailVerification().then(function() {
                          
                        })
                        .then(()=> {
                          window.location.pathname = '/'
                        })
                        .catch(function(error) {
                          
                          window.alert(error)
                        });
                    
                  })
                  
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                    window.alert(error)
                  });
              })
              .catch((error) => {
                
                window.alert(error)
              })
            })
          }

  handleChange(e) {

    this.setState({ [e.target.name]: e.target.value });
  }


render() {

    return(
      <Row>
        <Col className="loginSignupCol" s={10} m={10} l={4} offset="l4 m1 s1">

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Create your account</h4>

                <Input type="email" validate label="Email" name="emailVerified" s={12} value={this.state.emailVerified} onChange={this.handleChange}/>
                <Input minLength={5} type="password" validate label="Password" name="password" s={12} value={this.state.password} onChange={this.handleChange}/>
                <Input minLength={3} type="text" validate label="Company name" name="team" s={12} value={this.state.team} onChange={this.handleChange}/>

              <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary" id="SignUp" onClick={this.handleSignup}>Sign up</Button>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={12} l={12} >
                  <NavLink to="/">
                    <p>Already a user? Sign in here</p>
                  </NavLink>
                  <NavLink to="/join-a-team">
                    <p>Join an existing team</p>
                  </NavLink>
                </Col>
              </Row>
          </Col>

        </Col>
      </Row>
    )
  }
}

export default CreateAccount
