import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import {NavItem} from 'react-materialize';
import ComponentCSS from './../ComponentCSS.css';

const dropDownOptions = [];
  
  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  // function escapeRegexCharacters(str) {
  //   return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // }

  // function getSuggestions(value) {
  //   const escapedValue = escapeRegexCharacters(value.trim());
    
  //   if (escapedValue === '') {
  //     return [];
  //   }
  
  //   const regex = new RegExp('^' + escapedValue, 'i');
  
  //   return dropDownOptions.filter(option => regex.test(option.name));
  // }
  
  // function getSuggestionValue(suggestion) {
  //   return suggestion.name;
  // }
  
  // function renderSuggestion(suggestion) {
    
  //   return (
  //   //   <span>{suggestion.name}</span>
  //   <span className={'suggestion-content ' + suggestion.name}>
  //     <li className="dropDownList">{suggestion.name}</li>
  //   </span>
  //   );
  // }
  
  class MediumInput extends React.Component {
    constructor() {
      super();
  
      this.state = {
        value: '',
        suggestions: [],
        listicles: []
      };    
    }

    onChange = (event, { newValue, method }) => {
        this.props.updateState(newValue);
        this.setState({
        value: newValue
      });
      
    };

    componentDidMount = () => {
        let listicleArray = []
        
        this.props.list.forEach(function(item){
        dropDownOptions.push({"name": item})
        

        })
        
        this.setState({
          listicles: dropDownOptions
        });
      };

    componentWillUnmount() {
      dropDownOptions.splice(0,dropDownOptions.length)
      this.setState({
        listicles: []
      })
    }
    // IDEA: Need to empty the list, as it's filled in again when opening the builder for the second time
  
    escapeRegexCharacters(str) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestions(value) {
      const escapedValue = this.escapeRegexCharacters(value.trim());
      
      if (escapedValue === '') {
        return [];
      }
    
      const regex = new RegExp('^' + escapedValue, 'i');
    
      return this.state.listicles.filter(option => regex.test(option.name));
    }
    
    getSuggestionValue(suggestion) {
      return suggestion.name;
    }
    
    renderSuggestion(suggestion) {
      
      return (
      //   <span>{suggestion.name}</span>
      <span className={'suggestion-content ' + suggestion.name}>
        <li className="dropDownList">{suggestion.name}</li>
      </span>
      );
    }

    
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
  
    render() {
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: this.props.type,
        value,
        onChange: this.onChange,
        onFocus: this.props.onFocus,
        className: this.props.className
      };
  
      return (
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} />
      );
    }
  }
  
  export default MediumInput