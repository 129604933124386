import React, {Component} from 'react';
import {Button, Icon, Col } from 'react-materialize';
import '../ComponentCSS.css'
import { toast } from 'react-toastify';

function CopyText(props) {

  function handleCopy(text) {
    
     const newInput = document.createElement('textarea');
         newInput.value = text;
         document.body.appendChild(newInput);
         newInput.select();
         document.execCommand('copy');
         document.body.removeChild(newInput);
         toast("UTM Copied 🤘🏽", {
           draggablePercent: 60,
           className: 'notify-success',
           bodyClassName: "notify-text",
           progressClassName: 'notificationProgressBar'
         });
       }

  return(
        <Button onClick={() => { handleCopy(props.text ? props.text : "something went wrong with copying")} }className={props.class ? props.class : "ghostButtonBorder"}>
            <Icon>{props.icon ? props.icon : "file_copy"}</Icon>
        </Button>

  )

}

export default CopyText
