import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row} from 'react-materialize';
import './../ComponentCSS.css'
import * as firebase from 'firebase';

function CampaignMenuItem(props) {

  const link = "/campaign/"+props.data.campaignId;
  

function countUTMS(campaignToCheck) {
    const db = firebase.firestore();
    let countList = ''
    // get utms data from firebase
    db.collection("utm").where("campaignId", "==",campaignToCheck).get()
     .then((doc) => {
      
      countList = doc.size
      return countList
  })
  
}

  return(

    <Col className="valign-wrapper underline" l={12} m={12} s={12}>

        <Col className="" l={4} m={4} s={4}>

            <Icon className="icon">folder</Icon>

        </Col>

        <Col l={4} m={4} s={4}>

            <p className="white-text center"style={{wordWrap: "break-word"}}>{props.data.campaignName}</p>

        </Col>

        <Col l={4} m={4} s={4}>

            <p className="white-text center" style={{wordWrap: "break-word"}}>{props.UTMCount > 0 ? props.UTMCount : 0 }</p>
            <p>{countUTMS(props.id)}</p>

        </Col>

    </Col>
  )

}


export default CampaignMenuItem
