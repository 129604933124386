import React, { Component } from "react";
import './../MainContent.css';
import './../ComponentCSS.css';
import UtmItem from './../UtmItem';
import {Data} from './../providers/data';
import { Campaigns } from './../providers/Campaigns';
import { Button, Card, Row, Col, Input, Icon, Preloader } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './../CampaignItem';
import SubNav from './../SubNav'
import { UserInfo } from './../providers/userinfo';
import { TeamInfo } from './../providers/teaminfo';
import * as firebase from 'firebase';
import fire from './../fire';
import UtmActions from './UtmActions';
import ActionCard from './ActionCard';
import UtmListItem from './UtmListItem';

class SingleUtm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      filter: '',
      campaignUtms: '',
      singleUtm: '',
      rights: 'admin',
      teamId: '',
      teamName: ''
    }
  }


  handleChange(e) {

    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {

  }

  render() {

    return (
      <Data.Consumer>{(dataSet) => {
        const utms = []
        var singleUTM = null;
        var fullUTMLink = null;
        dataSet.map(utm => {
          const utmId = utm.id
          const utmData = utm.data


          //get the url data
          const utmRef = this.props.match.params.utmId;
          const campaignRef = this.props.match.params.id;

          //check if campaign and utm are equal to the url. If so, add to state
          if (utmData.campaignId == campaignRef && utm.id == utmRef) {
            singleUTM = utm;
            fullUTMLink = utmData.fullUTM;
          }
          //if id is not equal but the campaign is, then add to utms array object and push that to state
          else if (utmData.campaignId == campaignRef && utm.id != utmRef) {
            utms.push(utm)

          } else {
            //else return nothing. UTMS are out of campaign scope
            return
          }
        })

        return (
          
          <div className="dashboardMain" s={12} M={12} l={12}>
            <div className="dashboardHeader primary">
              <h1>UTM</h1>

                <Row>

                        {/* <Col s={8} m={8} l={8} offset="l2 m2 s2" className="searchbar">
                          <Input s={12} label="" className="grey-text active-color" defaultValue="" onChange={this.handleFilter} placeholder="search" ><Icon className="md-dark md-inactive">search</Icon></Input>
                        </Col> */}
                </Row>
                  {/* <Col s={6} m={6} l={6} offset="l3 m3 s3">
                          <h1><strong>Build and manage your UTMs</strong></h1>
                  </Col> */}
                {/* <Row className="noBottomMargin">
                        <Col s={8} m={8} l={6} offset="l3 m2 s2">
                          <Col s={6} m={6} l={4}>
                            <ActionCard type="utm" length={2} />
                          </Col>
                          <Col s={6} m={6} l={4}>
                            <ActionCard type="campaign" length={3} />
                          </Col>
                          <Col s={12} m={6} l={4}>
                            <ActionCard type="user" length={3} />
                          </Col>
                        </Col>                        
                </Row> */}

                
              </div>
              <div className="">
                    <Row>
                      <Col s={10} m={8} l={8} offset="l2 m2 s1" >
                        <Col s={12} m={12} l={6}  className="searchbar" >
                        {singleUTM ? <h2>{singleUTM.data.fullUTM}</h2> : ''}
                      {/* {dataSet.length > 0 ? <h5 className="dark-text">Your UTMs for {this.props.userInfo.team}</h5> : <h5>No UTMs created.</h5> } */}
                      
                      
                      
                        {/* {this.props.data.slice(0,5).map(utm => {

                          const utmId = utm.id;
                          const utmData = utm.data;
                          const link = `/campaigns/${utmData.campaignId}/utm/${utm.id}`;
                          
                          let creator = this.props.teamMembers.find(creator => creator.id == utmData.creator);

                          if(this.state.filter == "") {
                            return (
                                <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>
                            )
                          } else {
                            const name = utmData.fullUTM.toUpperCase();;
                            const query = this.state.filter.toUpperCase();;
                            if (name.includes(query)) {
                              return (

                                <UtmListItem data={utmData} creator={creator} key={utm.id} id={utm.id} link={link} type="list" createDate={utmData.createDate.toDate()}/>

                              )

                          }

                          }
                        })} */}

                      </Col>



                      <Col s={12} m={12} l={6} className="searchbar">
                      {/* {dataSet.length > 0 ? <h5 className="dark-text">Your campaigns for {this.props.userInfo.team}</h5> : <h5>No campaigns yet</h5> } */}
                        {/* {dataSet.slice(0,5).map(campaign => {
                          const campaignId = campaign.id;
                          const campaignData = campaign.data;
                          const link = `/campaigns/${campaign.id}`;
                          
                          if(this.state.filter == "") {
                            return (

                              <NavLink to={link}>
                                <CampaignItem data={campaign} key={campaign.id}/>
                              </NavLink>
                            )
                          } else {
                            const name = campaignData.campaignName.toUpperCase();;
                            const query = this.state.filter.toUpperCase();;
                            if (name.includes(query)) {
                              return (
                              <NavLink to={link}>
                                <CampaignItem data={campaign} key={campaign.id}/>
                              </NavLink>
                              )

                          }

                          }

                        })} */}
                      </Col>
                        <Col s={12} m={12} l={6} className="searchbar">
                          
                        </Col>
                      </Col>
                    </Row>
              </div>
          </div>
          )

        // return (
        //   <div className="dashboardMain">

        //   <div className="dashboardHeader primary">

        //             <div className="mainContentUtm">
        //               <Row>
        //                 <Col s={8} m={8} l={6} offset="l3 m2 s2" className="campaignHeader">
        //                     <Col s={12} m={12} l={12} >
        //                       <h1>{singleUTM ? singleUTM.data.campaign : <Preloader size="small" />}</h1>

        //                     </Col>
        //                     <Col s={12} m={6} l={4}>
        //                       <p>url:</p>
        //                       <h5>{singleUTM ? singleUTM.data.url : ""}</h5>
        //                     </Col>
        //                     <Col s={12} m={6} l={4}>
        //                       <p>source/medium</p>
        //                       <h5>{singleUTM ? `${singleUTM.data.source}/${singleUTM.data.medium}`: ""}</h5>
        //                     </Col>
        //                     <Col s={12} m={6} l={4}>
        //                       <p>term/content:</p>
        //                       <h5>{singleUTM ? `${singleUTM.data.term}/${singleUTM.data.content}`: ""} </h5>
        //                     </Col>
        //                     <Col s={12} m={12} l={12} >

        //                       <UtmActions data={fullUTMLink} id={this.props.match.params.utmId}/>
        //                     </Col>
        //                 </Col>
        //               </Row>
        //             </div>

        //             <div className="utmGrid">
        //             <Row>
        //               <Col s={8} m={8} l={6} offset="l3 m2 s2" className="utmStats" >
        //                 <Row>
        //                     <Col s={12} m={6} l={3} >
        //                       <h6>Clicks</h6>
        //                       <h3>101</h3>
        //                     </Col>
        //                     <Col s={12} m={6} l={3} >
        //                       <h6>Pages</h6>
        //                       <h3>303</h3>
        //                     </Col>
        //                     <Col s={12} m={6} l={3} >
        //                       <h6>Pages/Session</h6>
        //                       <h3>3</h3>
        //                     </Col>
        //                     <Col s={12} m={6} l={3} >
        //                       <h6>Avg. Session duration</h6>
        //                       <h3>0:03:21</h3>
        //                     </Col>
        //                 </Row>
        //               </Col>

        //               <Col s={8} m={8} l={6} offset="l3 m2 s2" className="utmStats" >
        //                 <Row>
        //                   <Col s={12} m={6} l={6}>

        //                     <Input type="text" label="url" name="url" s={12} placeholder={singleUTM ? singleUTM.data.url : ""} onChange={this.handleChange}/>
        //                     <Input type="text" label="source" name="source" s={12} placeholder={singleUTM ? singleUTM.data.source : ""} value={this.state.source} onChange={this.handleChange}/>
        //                     <Input type="text" label="medium" name="medium" s={12} placeholder={singleUTM ? singleUTM.data.medium : ""} value={this.state.medium} onChange={this.handleChange}/>
        //                     <Input type="text" label="term" name="term" s={12} placeholder={singleUTM ? singleUTM.data.term : ""} value={this.state.term} onChange={this.handleChange}/>
        //                     <Input type="text" label="content" name="content" s={12} placeholder={singleUTM ? singleUTM.data.content : ""} value={this.state.content} onChange={this.handleChange}/>

        //                     <Button className="primary" onClick={this.handleSignup}><Icon>edit</Icon>Update</Button>

        //                   </Col>

        //                   <Col s={8} m={6} l={6}>
        //                       <Input type="text" label="description" name="description" s={12}/>

        //                       <Input type="switch" offLabel="Off" onLabel="On"/>

        //                   </Col>
        //                 </Row>
        //               </Col>
        //             </Row>

        //               <Row>


        //               </Row>
        //             </div>
        //           </div>


        //         </div>
        //     )
    }
  }</Data.Consumer>
    );
  }
}

export default SingleUtm;
