import React, {Component} from 'react';
import './ComponentCSS.css';
import { Icon } from 'react-materialize';
import * as firebase from 'firebase';
import fire from './fire';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
class MenuItem extends Component {

  render() {

    return(
      <div className="MenuItem">
       
          <Icon id={this.props.item}>{this.props.item}</Icon>
          <h5 className="menuItemText">{this.props.type}</h5>
        

      </div>
    )
  }
}

export default MenuItem
