import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, Icon, Input, Modal, Toast} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notify from './notify';

class BuilderModal extends Component {
  constructor() {
    super()

    this.createCampaign = this.createCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.state = {
      url: '',
      source: '',
      medium: '',
      campaign: '',
      campaignId: '',
      term: '',
      content: '',
      team: '',
      userId: '',
      utmsBuild: [],
      fullUTM: '',
      forceLowerCase: true
    }

  }

    componentDidMount() {
      
    }


  createCampaign(e) {
    e.preventDefault();
    //if the url and source and medium are all filled in.
    if(this.state.url.length > 2 && this.state.source.length > 0 && this.state.medium.length > 0) {

    const db = firebase.firestore();
    const teamRef = this.props.teamRef[0].data.teamRef;
    
    const passState = this.state;
    const passProps = this.props;
    const fullUTM = this.state.forceLowerCase ?
     `${this.state.url ? encodeURI(this.state.url.trim()) : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`.toLowerCase() :
     `${this.state.url ? encodeURI(this.state.url.trim()) : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`

      db.collection("campaign").where("teamRef", "==", teamRef).where("campaignName", "==", this.state.campaign).get()
      .then(function(doc) {
        


        if (doc.empty === false) {
            const docId = doc.docs[0].id;
            const campaignRef = db.collection('campaign').doc(docId)
              // IDEA: this won't work if the campaign does not have a utm.
              //create UTM here with existing campaign ID reference
              //campaign, campaignRef, content, term, source, medium, url, fullUTM, team, userId ref
              db.collection("utm").doc().set({
                  campaign: passState.campaign.trim(),
                  campaignRef: campaignRef,
                  campaignId: docId,
                  teamRef: teamRef,
                  content: passState.content.trim(),
                  term: passState.term.trim(),
                  source: passState.source.trim(),
                  medium: passState.medium.trim(),
                  url: passState.url.trim(),
                  team: passProps.teamRef[0].data.teamName,
                  userId: passState.userId,
                  fullUTM: fullUTM,
                  createDate: firebase.firestore.Timestamp.fromDate(new Date())
                })
                .then(function(doc) {
                    

                    toast("UTM Saved 👨🏻‍💻", {
                      draggablePercent: 60,
                      className: 'notify-success',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });


                })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                    toast("🤖 ERROR: " + error, {
                      draggablePercent: 60,
                      className: 'notify-error',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });
                });



          } else {
              // doc.data() will be undefined in this case
              // Create new campaign and then a new UTM with corresponding campaign ID reference
              db.collection("campaign").add({
                      campaignName: passState.campaign.trim(),
                      creatorId: fire.auth().currentUser.uid,
                      teamName: passProps.userInfo.team,
                      teamRef: passProps.userInfo.teamRef,
                      createDate: firebase.firestore.Timestamp.fromDate(new Date())
                  })
                  .then(function(docRef) {
                      toast(`A new campaign '${passState.campaign.trim()}' has been created 👨🏻‍💻`, {
                        draggablePercent: 60,
                        className: 'notify-success',
                        bodyClassName: "notify-text",
                        progressClassName: 'notificationProgressBar'
                      });
                      //create campaignReference
                      const campaignRef = db.collection('campaign').doc(docRef.id);
                      //createUTM
                      db.collection("utm").doc().set({
                          campaign: passState.campaign.trim(),
                          campaignRef: campaignRef,
                          teamRef: teamRef,
                          campaignId: docRef.id,
                          content: passState.content.trim(),
                          term: passState.term.trim(),
                          source: passState.source.trim(),
                          medium: passState.medium.trim(),
                          url: passState.url.trim(),
                          team: passProps.userInfo.team,
                          userId: passState.userId,
                          fullUTM: fullUTM,
                          createDate: firebase.firestore.Timestamp.fromDate(new Date())
                        })
                        .then(function(docRef) {
                          toast(`We added your UTM to your new campaign: '${passState.campaign.trim()}'`, {
                            draggablePercent: 60,
                            className: 'notify-success',
                            bodyClassName: "notify-text",
                            progressClassName: 'notificationProgressBar'
                          });
                        })
                        .catch(function(error) {
                            console.error("Error adding document: ", error);
                            toast("🤖 ERROR: " + error, {
                              draggablePercent: 60,
                              className: 'notify-error',
                              bodyClassName: "notify-text",
                              progressClassName: 'notificationProgressBar'
                            });
                        });

                  })
                  .catch(function(error) {
                      console.error("Error adding document: ", error);
                      toast("🤖 ERROR: " + error, {
                        draggablePercent: 60,
                        className: 'notify-error',
                        bodyClassName: "notify-text",
                        progressClassName: 'notificationProgressBar'
                      });
                  });

          }
      })
    } else {
      toast("URL, Source, Medium and campaign are required fields", {
        draggablePercent: 60,
        className: 'notify-error',
        bodyClassName: "notify-errortext",
        progressClassName: 'notificationProgressBar'
      })
    }

  }

  handleChange(e) {
    const db = firebase.firestore();
    this.setState({
      [e.target.name]: e.target.value,
      userId: db.doc('user/' + fire.auth().currentUser.uid),
      team: this.props.teamName,
      teamRef: this.props.teamRef,
      campaign: this.props.campaign
    });
  }

  handleSwitch(e) {
    this.setState({
      forceLowerCase: !this.state.forceLowerCase
    })
  }


render() {

    return(
      <Modal header="" fixedFooter trigger={
      // <Button className="primary ghostButton align-center"><Icon>add</Icon> Add new UTM</Button>
      <Button floating icon='add' className='fixed-action-btn primary waves' large style={{bottom: '45px', right: '24px'}}>
      </Button>
      }>

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Add a new UTM to your "{this.props.campaign}" campaign</h4>

                <Input type="text" label="url" name="url" s={12} value={this.state.url} onChange={this.handleChange}/>
                <Input type="text" label="source" name="source" s={12} value={this.state.source} onChange={this.handleChange}/>
                <Input type="text" label="medium" name="medium" s={12} value={this.state.medium} onChange={this.handleChange}/>
                <Input type="text" disabled name="campaign" s={12} value={this.props.campaign ? this.props.campaign : this.state.campaign} onChange={this.props.campaign ? "" :this.handleChange}/>
                <Input type="text" label="term" name="term" s={12} value={this.state.term} onChange={this.handleChange}/>
                <Input type="text" label="content" name="content" s={12} value={this.state.content} onChange={this.handleChange}/>
                <p>Force lowercase</p>
                <Input type="switch" label="forceLowerCase" name="forceLowerCase" s={12} checked={this.state.forceLowerCase} onChange={this.handleSwitch}/>

                <Col s={10} m={10} l={10} offset="l1 m1 s1" className="left-align grey-text">
                  <p>{this.state.forceLowerCase ?
                    `${this.state.url ? encodeURI(this.state.url.trim()) : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`.toLowerCase() :
                    `${this.state.url ? encodeURI(this.state.url.trim()) : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source.trim()) : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium.trim()) : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign.trim()) : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term.trim()) : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content.trim()) : "" }`
                  }</p>
                </Col>
              <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary modal-action modal-close" id="logIn" onClick={this.createCampaign}>
                    Create
                  </Button>
                </Col>
                </Row>
          </Col>
        </Modal>
    )
  }
}

export default BuilderModal
