import React, {Component} from "react"
import './ProfilePic.css'
import jhonDoe from './images/unknown-deer.jpg'
import unknown from './images/unknown.png'

class ProfilePic extends Component {

  render() {
    return(
      <div className="ProfilePicDiv">
        <img className="ProfileImage" src={this.props.profilePic ? this.props.profilePic : unknown } />
        <h5 className="userName">{this.props.user ? this.props.user : "Hi, Stranger"}</h5>
      </div>
    )
  }
}

export default ProfilePic
