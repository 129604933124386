import React, { Component } from "react";
import './../MainContent.css';
import './../ComponentCSS.css';
import UtmItem from './../UtmItem';
import {Data} from './../providers/data';
import { Campaigns } from './../providers/Campaigns';
import { Button, Card, Row, Col, Input, Icon, Table } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './../CampaignItem';
import SubNav from './../SubNav'
import { UserInfo } from './../providers/userinfo';
import { TeamInfo } from './../providers/teaminfo';
import * as firebase from 'firebase';
import fire from './../fire';
import ProfilePic from './../ProfilePic';

class ProfileSettings extends Component {
  constructor(props) {
    super(props)
    this.getUser = this.getUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleProfileChange = this.handleProfileChange.bind(this)
    this.state = {
      filter: '',
      fullName: '',
      role: '',
      profileLink: '',
      rights: 'admin',
      teamId: '',
      teamName: '',
      userId: ''
    }
  }


  handleChange(e) {

    this.setState({ [e.target.name]: e.target.value });
  }

  handleProfileChange() {
    const userId = this.state.userId;
    const role = this.state.role;
    const profilePicture = this.state.profileLink;
    
    if (this.state.fullName.length > 0 && this.state.role.length > 0 && this.state.profileLink.length > 0) {

      var user = firebase.auth().currentUser;

        user.updateProfile({
          displayName: this.state.fullName,
          photoURL: this.state.profileLink
        })
        .then(function() {
          // Update successful.
          //Update the user database with the role
          const db = firebase.firestore();
          db.collection("user").doc(userId).update({
            role: role,
            profilePicture: profilePicture
          }).then(function() {
            
          })

        })
        .then(function() {
          // Update successful.
          window.alert("Updated your profile")
        }).catch(function(error) {
          window.alert(error)
        });
      } else {
        window.alert('Fill in all the fields to successfully update your settings')
      }
  }

  componentDidMount() {
      this.getUser()
  }

  getUser() {

    this.setState({
      users: "userIds",
      teamId: '17',
      teamName: 'Foleon',
      userId: this.props.userId
    })
  }

  render() {

    return (
      <UserInfo.Consumer>{(dataSet) => {

        return (
              <div className="MainContent">

                  <div className="MainContent-header">

                    <div className="mainContentUtm">

                      <Row>
                        <Col s={8} m={8} l={8} offset="l2 m2 s2" className="campaignHeader">
                            <Col s={8} m={8} l={8} offset="l2 m2 s2">
                              <h4>Hi, {this.props.data.displayName ? this.props.data.displayName : "Stranger"}</h4>
                              <p>Welcome to your personal profile page. Here you can manage your settings and set up your account👨🏻‍💻.</p>
                            </Col>
                        </Col>

                      </Row>
                    </div>

                    <div className="utmGrid">
                      <Row>
                        <Col className="utmStats " s={8} m={8} l={8} offset="l2 m2 s2">
                              <Col className="profileSettingsCard" s={12} m={12} l={12}>
                                  <h3 data-field="id">Personal settings</h3>
                                    <Col s={12} m={12} l={12}>
                                      <Col s={12} m={6} l={6} >
                                        <Input type="text" label="Your full Name" name="fullName" defaultValue={this.props.data.displayName} s={8} onChange={this.handleChange} />
                                        <Input type="select" label="Your role" name="role" s={8} onChange={this.handleChange} >
                                            <option value="" disabled selected>
                                            Choose your option
                                            </option>
                                            <option value="Admin">
                                            Admin 👨🏻‍💻
                                            </option>
                                            <option value="Analyst">
                                            Analyst 🧐
                                            </option>
                                            <option value="Communications Specialist">
                                            Communications Specialist 📓
                                            </option>
                                        </Input>
                                          <Input type="text" label="Profile Image URL" name="profileLink" defaultValue={this.props.data.photoURL ? this.props.data.photoURL : dataSet.profileLink} s={8} onChange={this.handleChange} />
                                          <Col s={12} m={12} l={12} className="left-align">
                                            <Button className="primary left-align" onClick={this.handleProfileChange} >Update Profile</Button>
                                          </Col>
                                      </Col>

                                      <Col s={12} m={6} l={6}>
                                        <Row>
                                          <Col m={12} s={12}>
                                          <Card >
                                          {this.state.profileLink ? <ProfilePic user={this.state.fullName} profilePic={this.state.profileLink} />
                                            : <ProfilePic user={this.props.data.displayName} profilePic={this.props.data.photoURL} />}
                                          <h5>{this.state.fullName ? this.state.fullName : this.props.data.displayName}</h5>
                                          <h5>{this.state.role ? this.state.role : dataSet.role }</h5>
                                          </Card>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Col>

                              </Col>


                        </Col>

                      </Row>
                    </div>
                  </div>


                </div>
            )
    }
  }</UserInfo.Consumer>
    );
  }
}

export default ProfileSettings;
