import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Card, Row, Col, ProgressBar, Input, Modal} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

class CreateCampaignModal extends Component {
  constructor() {
    super()

    this.createNewCampaign = this.createNewCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.state = {
      campaign: '',
      team: '',
      userId: '',
      showError: false,
      errorMessage: '',
      forceLowerCase: true
    }

  }

    componentDidMount() {
      
    }

  createNewCampaign(e) {
    e.preventDefault();
    if(this.state.campaign.length > 0) {
    
    const db = firebase.firestore();
    const teamRef = this.props.data[0].data.teamRef;
    
    const passState = this.state;
    const passProps = this.props;
    //if campaign already exists, give an error; otherwise create campaign
      db.collection("campaign").where("teamRef", "==", teamRef).where("campaignName", "==", this.state.campaign).get()
      .then(function(doc) {
        
        //if doc exists
        if (doc.empty === false) {
            const docId = doc.docs[0].id;
            const campaignRef = db.collection('campaign').doc(docId)
            const link = "/campaign/" + docId;
              //create UTM here with existing campaign ID reference
              //campaign, campaignRef, content, term, source, medium, url, fullUTM, team, userId ref
              // window.alert("It looks like you already created this campaign. Go to /campaign/" + docId)
              toast("It looks like you already created this campaign.", {
                draggablePercent: 60,
                className: 'notify-error',
                bodyClassName: "notify-text",
                progressClassName: 'notificationProgressBar'
              });

          } else {
              // doc.data() will be undefined in this case
              // Create new campaign
              db.collection("campaign").add({
                      campaignName: passState.campaign,
                      creatorId: fire.auth().currentUser.uid,
                      teamName: passProps.data[0].data.teamName,
                      teamRef: teamRef,
                      createDate: firebase.firestore.Timestamp.fromDate(new Date())
                  })
                  .then(function(docRef) {
                    //create campaignReference

                      window.location.pathname = "campaigns/" + docRef.id
                      //createUTM
                      //redirect to the new campaign

                  })
                  .catch(function(error) {
                      console.error("Error adding document: ", error);
                  });

          }
      })
    } else {
      toast("Every campaign needs a name. It needs to be at least 1 character", {
        draggablePercent: 60,
        className: 'notify-error',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      })
    }
  }

  handleChange(e) {
    const db = firebase.firestore();

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSwitch(e) {
    this.setState({
      forceLowerCase: !this.state.forceLowerCase
    })
  }


render() {

    return(
      <Modal header="" trigger={<Button tooltip="Create a new campaign" className="primary" >
        Create New Campaign
      </Button>}>

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Create your new campaign</h4>
                <Input type="text" label="Name your campaign" name="campaign" s={12} value={this.state.campaign} onChange={this.handleChange}/>
                <p>Force lowercase</p>
                <Input type="switch" label="forceLowerCase" name="forceLowerCase" s={12} checked={this.state.forceLowerCase} onChange={this.handleSwitch}/>

                <Col s={10} m={10} l={10} offset="l1 m1 s1" className="left-align grey-text">
                  <p>{this.state.forceLowerCase ?
                    `${this.state.campaign ? this.state.campaign.trim() : "" }`.toLowerCase() :
                    `${this.state.campaign ? this.state.campaign.trim() : "" }`
                  }</p>
                </Col>
              <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary modal-action modal-close" id="createCampaign" onClick={this.createNewCampaign}>
                    Create {this.state.forceLowerCase ?
                      `${this.state.campaign ? this.state.campaign.trim() : "" }`.toLowerCase() :
                      `${this.state.campaign ? this.state.campaign.trim() : "" }`
                    }
                  </Button>
                </Col>
                </Row>
          </Col>
        </Modal>
    )
  }
}

export default CreateCampaignModal
