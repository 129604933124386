import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row} from 'react-materialize';
import './../ComponentCSS.css';
import DeleteItem from './deleteItem';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

class UtmActions extends Component {
    constructor(props) {
      super(props)
        this.handleCopy = this.handleCopy.bind(this);
    }

    handleClick(e) {
      window.alert('you clicked: ' + e.target)
    }

    handleCopy() {
      //window.alert('you clicked: ' + this.props.data)
      const newInput = document.createElement('textarea');
      newInput.value = this.props.data;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand('copy');
      document.body.removeChild(newInput);
      toast("UTM Copied 🤘🏽", {
        draggablePercent: 60,
        className: 'notify-success',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });
    }

    render() {


    return(
      <div>

          <Col s={4} m={4} l={4}>
                <Button tooltip="Copy UTM" className="transparent transparentButton grey-text center" id="Copy" onClick={this.handleCopy} ><Icon className="md-light ">file_copy</Icon></Button>
          </Col>
          <Col s={4} m={4} l={4}>
                <DeleteItem id={this.props.id}/>
          </Col>
          {/* <Col s={4} m={4} l={4}>
                <NavLink id="Edit" className="transparent transparentButton grey-text center" to={this.props.editLink}>
                  <Button className="transparent transparentButton grey-text center" tooltip="Edit UTM">
                    <Icon className="md-light ">
                      edit
                    </Icon>
                  </Button>
                </NavLink>
          </Col> */}

      </div>
    )
  }
}

export default UtmActions
