import React, {Component} from 'react';
import logo from './../logo.svg';
import { Button, Autocomplete, Row, Col, Chip, Tag, Input, Modal, Icon, Toast} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './../fire';
import ComponentCSS from './../ComponentCSS.css';
import { UserInfo } from './../providers/userinfo';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import UtmListItem from './UtmListItem';
import SourceInput from './SourceInput';
import MediumInput from './MediumInput';
import {SuccessPhrase} from './SuccessMessages';

class Builder extends Component {
  constructor() {
    super()
    this.createNewCampaign = this.createNewCampaign.bind(this);
    this.createUTM = this.createUTM.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleMediumChange = this.handleMediumChange.bind(this);
    this.getCampaigns = this.getCampaigns.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleBadgeSelect = this.handleBadgeSelect.bind(this);
    this.addToBadgeList = this.addToBadgeList.bind(this);
    this.closeBadgeSelect = this.closeBadgeSelect.bind(this)
    this.removeFromBadgelist = this.removeFromBadgelist.bind(this)
    this.handleBadgeUtms = this.handleBadgeUtms.bind(this)
    this.state = {
      url: '',
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      team: '',
      userId: '',
      utmsBuild: [],
      fullUTM: '',
      campaignList: [],
      newCampaign: 'createNewCampaign',
      forceLowerCase: true,
      badge: false,
      badgeList: [],
      badgeQuery: '',
      saveAsTemplate: ''
    }

  }

    componentDidMount() {
      console.log(this.props)
      this.getCampaigns()
    }

  getCampaigns() {
    const db = firebase.firestore();
    let userRef = null;
    const userId = this.props.user.uid;
    console.log("Getting documents");
    db.collection("user").doc(userId).get()
    .then(function(doc) {
    if (doc.exists) {
        userRef = doc.data();

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch(function(error) {
    console.log("Error getting document:", error);
});

    const teamRef = this.props.userInfo.teamRef;
    console.log(teamRef);
    db.collection('campaign').where("teamRef", "==", teamRef)
    .onSnapshot((querySnapshot) => {
     var campaignList = [];
     querySnapshot.forEach(function(campaign) {      

         campaignList.push(campaign.data());
     });

   this.setState({
     campaignList: campaignList
       })
     })
  }

  


  createUTM(e) {
    e.preventDefault();
    if(this.state.url.length > 2 && this.state.source.length > 0 && this.state.medium.length > 0) {

    const db = firebase.firestore();
    const teamRef = this.props.userInfo.teamRef;
    const passState = this.state;
    const passProps = this.props;
    const fullUTM = this.state.forceLowerCase ? `${this.state.url ? encodeURI(this.state.url).trim().toLowerCase() : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim().toLowerCase() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim().toLowerCase() : "" }${this.state.newCampaign ? "&utm_campaign=" + encodeURI(this.state.newCampaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim().toLowerCase() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim().toLowerCase() : ""}` 
    : 
    `${this.state.url ? encodeURI(this.state.url).trim() : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.newCampaign ? "&utm_campaign=" + encodeURI(this.state.newCampaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : ""}`

    let campaignRef = this.props.campaigns.find(campaignRef => campaignRef.data.campaignName == this.state.newCampaign);
              //create UTM here with existing campaign ID reference
              //campaign, campaignRef, content, term, source, medium, url, fullUTM, team, userId ref
              const newCampaign = passState.newCampaign;
              const existingCampaign = passState.campaign;
            
              db.collection("utm").doc().set({
                  campaign: newCampaign,
                  campaignRef: db.collection("campaign").doc(campaignRef.id),
                  campaignId: campaignRef.id,
                  teamRef: teamRef,
                  content: passState.content,
                  term: passState.term,
                  source: passState.source,
                  medium: passState.medium,
                  url: passState.url,
                  team: passProps.userInfo.team,
                  userId: passState.userId,
                  fullUTM: fullUTM,
                  createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                  creator: fire.auth().currentUser.uid
                })
                .then(function(doc) {
                    console.log(SuccessPhrase);
                    toast(SuccessPhrase, {
                      draggablePercent: 60,
                      className: 'notify-success',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });
                })
                .then(function(doc) {
                  console.log("template length => ", passState.saveAsTemplate.length)
                  if(passState.saveAsTemplate.length > 0) {
                    console.log('creating a template')
                    const templateString= passState.forceLowerCase ? `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim().toLowerCase() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim().toLowerCase() : "" }${passState.newCampaign ? "&utm_campaign=" + encodeURI(passState.newCampaign).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim().toLowerCase() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim().toLowerCase() : ""}` 
                    : 
                    `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim() : "" }${passState.newCampaign ? "&utm_campaign=" + encodeURI(passState.newCampaign).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim() : ""}`
                    db.collection("template").doc().set({
                      campaign: newCampaign,
                      content: passState.content,
                      createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      creator: fire.auth().currentUser.uid,
                      lastUsed: firebase.firestore.Timestamp.fromDate(new Date()),
                      medium: passState.medium,
                      source: passState.source,
                      teamId: passProps.team.teamId,
                      teamRef: teamRef,
                      teamName: passProps.team.teamName,
                      templateName: passState.saveAsTemplate,                   
                      term: passState.term,
                      timesUsed: 1,
                      templateString: templateString,
                      campaignId: campaignRef.id
                    })
                    toast("Template created", {
                      draggablePercent: 60,
                      className: 'notify-success',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });
                    // this.setState({
                    //   saveAsTemplate: ''
                    // })
                  }
              })
              
                .catch(function(error) {
                    console.log("ERROR => ", error)
                    toast("error: something went wrong", {
                      draggablePercent: 60,
                      className: 'notify-error',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });
                });
          
        } else {
          toast("URL, Source, Medium and campaign are required fields", {
            draggablePercent: 60,
            className: 'notify-error',
            bodyClassName: "notify-text",
            progressClassName: 'notificationProgressBar'
          })
        }
    }

  createNewCampaign(e) {
    e.preventDefault();
    console.log("CreateNewCampaign starts here")
    if(this.state.url.length > 2 && this.state.source.length > 0 && this.state.medium.length > 0 && this.state.campaign.length > 0) {
    const db = firebase.firestore();
    const teamRef = this.props.userInfo.teamRef;
    const passState = this.state;
    const passProps = this.props;
    const campaignCreated = this.state.forceLowerCase ? this.state.campaign.toLowerCase() : this.state.campaign;
    const fullUTM = this.state.forceLowerCase ? `${this.state.url ? encodeURI(this.state.url).trim() : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : "" }`.toLowerCase()
    :
    `${this.state.url ? encodeURI(this.state.url).trim() : "" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : "" }`

      //if campaign is created and builder did not unmount, check if campaign exists in the database
      console.log("checking if campaign exists")
      db.collection("campaign").where("campaignName", "==", campaignCreated).where("teamName", "==", this.state.team).get()
      .then(function(querySnapshot) {
        console.log("getting querySnapshot", querySnapshot.empty)
        if(querySnapshot.empty == true) {
          console.log("doc is empty")
          // Create new campaign and then a new UTM with corresponding campaign ID reference
      db.collection("campaign").add({
        campaignName: campaignCreated,
        creatorId: fire.auth().currentUser.uid,
        teamName: passProps.userInfo.team,
        teamRef: passProps.userInfo.teamRef,
        createDate: firebase.firestore.Timestamp.fromDate(new Date())
    })
    .then(function(docRef) {
      toast("New campaign created 👨🏻‍💻", {
        draggablePercent: 60,
        className: 'notify-success',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });
        //create campaignReference
        const campaignRef = db.collection('campaign').doc(docRef.id);
        //createUTM
        db.collection("utm").doc().set({
            campaign: campaignCreated,
            campaignRef: campaignRef,
            teamRef: teamRef,
            campaignId: docRef.id,
            content: passState.content,
            term: passState.term,
            source: passState.source,
            medium: passState.medium,
            url: passState.url,
            team: passProps.userInfo.team,
            userId: passState.userId,
            fullUTM: fullUTM,
            createDate: firebase.firestore.Timestamp.fromDate(new Date()),
            creator: fire.auth().currentUser.uid
          })
          .then(function(docRef) {
            toast("New UTM created in your new campaign👨🏻‍💻", {
              draggablePercent: 60,
              className: 'notify-success',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            })
          })
          //create template if there is a template value
          .then(function(doc) {
            console.log("template length => ", passState.saveAsTemplate.length)
            if(passState.saveAsTemplate.length > 0) {
              console.log('creating a template')
              const templateString= passState.forceLowerCase ? `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim().toLowerCase() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim().toLowerCase() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim().toLowerCase() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim().toLowerCase() : ""}` 
              : 
              `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim() : ""}`
              db.collection("template").doc().set({
                campaign: campaignCreated,
                content: passState.content,
                createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                creator: fire.auth().currentUser.uid,
                lastUsed: firebase.firestore.Timestamp.fromDate(new Date()),
                medium: passState.medium,
                source: passState.source,
                teamId: passProps.team.teamId,
                teamRef: teamRef,
                teamName: passProps.team.teamName,
                templateName: passState.saveAsTemplate,                   
                term: passState.term,
                timesUsed: 1,
                templateString: templateString,
                campaignId: campaignRef.id
              })
              toast("Template created", {
                draggablePercent: 60,
                className: 'notify-success',
                bodyClassName: "notify-text",
                progressClassName: 'notificationProgressBar'
              });
            }
        })
          .catch(function(error) {
              console.error("Error adding document: ", error);
          });

    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
        } else {
          //campaign is already created once before. 
        querySnapshot.forEach(function(doc) {
          const campaign = doc.data().campaignName;
          const campaignId = doc.id
          console.log("check if it's the same: ", campaign, campaignCreated)
        if (campaign == campaignCreated) {
          //campaign is already created. So create campaign with that campaign ID
          console.log("it's the same")
          console.log("campaign is already created. so create campaign with that campaign ID", campaign, campaignCreated,campaignId)
          db.collection("utm").doc().set({
            campaign: campaignCreated,
            campaignRef: db.collection("campaign").doc(doc.id),
            teamRef: teamRef,
            campaignId: doc.id,
            content: passState.content,
            term: passState.term,
            source: passState.source,
            medium: passState.medium,
            url: passState.url,
            team: passProps.userInfo.team,
            userId: passState.userId,
            fullUTM: fullUTM,
            createDate: firebase.firestore.Timestamp.fromDate(new Date()),
            creator: fire.auth().currentUser.uid
          })
          .then(function(doc) {
            toast("New UTM created in campaign you just created before", {
              draggablePercent: 60,
              className: 'notify-success',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            })
          })
          //create template if there is a template value
          .then(function(doc) {
            console.log("template length => ", passState.saveAsTemplate.length)
            if(passState.saveAsTemplate.length > 0) {
              console.log('creating a template')
              const templateString= passState.forceLowerCase ? `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim().toLowerCase() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim().toLowerCase() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim().toLowerCase() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim().toLowerCase() : ""}` 
              : 
              `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim() : ""}`
              db.collection("template").doc().set({
                campaign: campaignCreated,
                content: passState.content,
                createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                creator: fire.auth().currentUser.uid,
                lastUsed: firebase.firestore.Timestamp.fromDate(new Date()),
                medium: passState.medium,
                source: passState.source,
                teamId: passProps.team.teamId,
                teamRef: teamRef,
                teamName: passProps.team.teamName,
                templateName: passState.saveAsTemplate,                   
                term: passState.term,
                timesUsed: 1,
                templateString: templateString,
                campaignId: campaignId
              })
              toast("Template created", {
                draggablePercent: 60,
                className: 'notify-success',
                bodyClassName: "notify-text",
                progressClassName: 'notificationProgressBar'
              });
            }
        })
          .catch(function(error) {
              console.error("Error adding document: ", error);
          });
        }
          else {
        console.log("campaign is new =>", campaignCreated)
      // Create new campaign and then a new UTM with corresponding campaign ID reference
      db.collection("campaign").add({
              campaignName: campaignCreated,
              creatorId: fire.auth().currentUser.uid,
              teamName: passProps.userInfo.team,
              teamRef: passProps.userInfo.teamRef,
              createDate: firebase.firestore.Timestamp.fromDate(new Date())
          })
          .then(function(docRef) {
            toast("New campaign created 👨🏻‍💻", {
              draggablePercent: 60,
              className: 'notify-success',
              bodyClassName: "notify-text",
              progressClassName: 'notificationProgressBar'
            });
              //create campaignReference
              const campaignRef = db.collection('campaign').doc(docRef.id);
              //createUTM
              db.collection("utm").doc().set({
                  campaign: campaignCreated,
                  campaignRef: campaignRef,
                  teamRef: teamRef,
                  campaignId: docRef.id,
                  content: passState.content,
                  term: passState.term,
                  source: passState.source,
                  medium: passState.medium,
                  url: passState.url,
                  team: passProps.userInfo.team,
                  userId: passState.userId,
                  fullUTM: fullUTM,
                  createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                  creator: fire.auth().currentUser.uid
                })
                .then(function(docRef) {
                  toast("New UTM created in your new campaign👨🏻‍💻", {
                    draggablePercent: 60,
                    className: 'notify-success',
                    bodyClassName: "notify-text",
                    progressClassName: 'notificationProgressBar'
                  })
                })
                //create template if there is a template value
          .then(function(docRef) {
            console.log("template length => ", passState.saveAsTemplate.length)
            if(passState.saveAsTemplate.length > 0) {
              console.log('creating a template')
              const templateString= passState.forceLowerCase ? `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim().toLowerCase() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim().toLowerCase() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim().toLowerCase() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim().toLowerCase() : ""}` 
              : 
              `${passState.source ? "?utm_source=" + encodeURI(passState.source).trim() : "" }${passState.medium ? "&utm_medium=" + encodeURI(passState.medium).trim() : "" }${campaignCreated ? "&utm_campaign=" + encodeURI(campaignCreated).trim() : "" }${passState.term ? "&utm_term=" + encodeURI(passState.term).trim() : "" }${passState.content ? "&utm_content=" + encodeURI(passState.content).trim() : ""}`
              db.collection("template").doc().set({
                campaign: campaignCreated,
                content: passState.content,
                createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                creator: fire.auth().currentUser.uid,
                lastUsed: firebase.firestore.Timestamp.fromDate(new Date()),
                medium: passState.medium,
                source: passState.source,
                teamId: passProps.team.teamId,
                teamRef: teamRef,
                teamName: passProps.team.teamName,
                templateName: passState.saveAsTemplate,                   
                term: passState.term,
                timesUsed: 1,
                templateString: templateString,
                campaignId: docRef.id
              })
              toast("Template created", {
                draggablePercent: 60,
                className: 'notify-success',
                bodyClassName: "notify-text",
                progressClassName: 'notificationProgressBar'
              });
            }
        })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                });

          })
          .catch(function(error) {
              console.error("Error adding document: ", error);
          });       
        }
      })
    }
   })
      } else {
        toast("URL, Source, Medium and campaign are required fields", {
          draggablePercent: 60,
          className: 'notify-error',
          bodyClassName: "notify-errortext",
          progressClassName: 'notificationProgressBar'
        })
      }

    }
    

  handleChange(e) {

    const db = firebase.firestore();
   
    this.setState({
      [e.target.name]: e.target.value,
      userId: db.doc('user/' + fire.auth().currentUser.uid),
      team: this.props.userInfo.team,
      teamRef: this.props.userInfo.teamRef
    });
  }

  handleSourceChange(name) {
    
    const db = firebase.firestore();
  
    this.setState({
      source: name,
      userId: db.doc('user/' + fire.auth().currentUser.uid),
      team: this.props.userInfo.team,
      teamRef: this.props.userInfo.teamRef
    });
  }

  handleMediumChange(name) {
    
    const db = firebase.firestore();
    
    this.setState({
      medium: name,
      userId: db.doc('user/' + fire.auth().currentUser.uid),
      team: this.props.userInfo.team,
      teamRef: this.props.userInfo.teamRef
    });
  }

  handleSwitch(e) {
    this.setState({
      forceLowerCase: !this.state.forceLowerCase
    })
  }

  handleBadgeSelect(e) {
    this.setState({
      badge: true
    });
  }

  closeBadgeSelect(e) {
    this.setState({
      badge: false
    });
  }

  addToBadgeList = (template) => {
    
    let items = this.state.badgeList

    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
          if (list[i].id === obj.id) {
              return i;
          }
      }
  
      return -1;
  }  
    //check here if e.target.innerText is already in the badgeList, if so, don't add it, else add it.
    let check = containsObject(template, items)
    // let check = items.includes(template.id)
    console.log(check, template.id)
    if(check >= 0) {
      // let index = items.indexOf(check)
      // console.log(index)
      // if (index > -1) {
        items.splice(check, 1)
      //}
      console.log(items)
      this.setState({
        badgeList: items
      })
    } else {
    // items.push(template.id)
    items.push(template)
      console.log(items)

      this.setState({
        badgeList: items
      })
    }
  }

  removeFromBadgelist = (item) => {
    
    let items = this.state.badgeList
    let index = items.indexOf(item)
      console.log(index)
      items.splice(index, 1)
      this.setState({
        badgeList: items
      })   
  }

  handleBadgeUtms() {
    
    let itemsProcessed = 0;
    this.state.badgeList.forEach(template => {
    
    if(this.state.url.length > 2) {

    const db = firebase.firestore();
    const badgeList = this.state.badgeList
    const teamRef = this.props.userInfo.teamRef;
    const passState = this.state;
    const passProps = this.props;
    const fullUTM = this.state.forceLowerCase ? `${this.state.url ? encodeURI(this.state.url).trim().toLowerCase() : "" }${template.data.templateString}`
    : 
    `${this.state.url ? encodeURI(this.state.url).trim() : "" }${template.data.templateString}`

    let campaignRef = this.props.campaigns.find(campaignRef => campaignRef.data.campaignName == template.data.campaign);
              //create UTM here with existing campaign ID reference
              //campaign, campaignRef, content, term, source, medium, url, fullUTM, team, userId ref
              const newCampaign = template.data.campaign;
              const existingCampaign = template.data.campaign;
      if(campaignRef) {
        //if campaign ref is true, then create template, otherwise warn the user that campaign is deleted.     
              db.collection("utm").doc().set({
                  campaign: newCampaign,
                  campaignRef: db.collection("campaign").doc(campaignRef.id),
                  campaignId: campaignRef.id,
                  teamRef: teamRef,
                  content: template.data.content,
                  term: template.data.term,
                  source: template.data.source,
                  medium: template.data.medium,
                  url: passState.url,
                  team: passProps.userInfo.team,
                  userId: passState.userId,
                  fullUTM: fullUTM,
                  createDate: firebase.firestore.Timestamp.fromDate(new Date()),
                  creator: fire.auth().currentUser.uid
                })
                .then(function(doc) {
                    console.log(fullUTM);
                    itemsProcessed++;
                    if (itemsProcessed == badgeList.length) {
                      toast(`${badgeList.length} ${badgeList.length === 1 ? "UTM" : "UTMS"} created (from template)`, {
                        draggablePercent: 60,
                        className: 'notify-success',
                        bodyClassName: "notify-text",
                        progressClassName: 'notificationProgressBar'
                      });
                    }
                    
                })              
                .catch(function(error) {
                    console.log("ERROR => ", error)
                    toast("error: something went wrong", {
                      draggablePercent: 60,
                      className: 'notify-error',
                      bodyClassName: "notify-text",
                      progressClassName: 'notificationProgressBar'
                    });
                });
              } else {
                toast("error: The campaign in this template seems to be deleted. Remove this template and create a new one", {
                  draggablePercent: 60,
                  className: 'notify-error',
                  bodyClassName: "notify-text",
                  progressClassName: 'notificationProgressBar'
                });
              }
          
        } else {
          toast("URL, Source, Medium and campaign are required fields", {
            draggablePercent: 60,
            className: 'notify-error',
            bodyClassName: "notify-text",
            progressClassName: 'notificationProgressBar'
          })
        }
      })
    }


render() { 

  const choices = {
        'Apple': null,
        'Microsoft': null,
        'Google': 'http://placehold.it/250x250'
      }

    return(

      <div className="builderContent">
        <Col className="builder left-align" s={12} m={12} l={12} >
          <Col className="" s={1} m={1} l={1} offset="l11 m11 s11" >
            <Button className="closeIcon" onClick={this.props.handleBuilder}><Icon>close</Icon></Button>
          </Col>

          <Col s={10} m={10} l={5} offset="l1 m1 s1">
            <h4 className="grey-text">Create your UTM</h4>
                
                <Input onFocus={this.closeBadgeSelect} type="text" label="url" name="url" s={12} value={this.state.url} onChange={this.handleChange}/>   
                {/* <Input type="select" multiple={true} label="Use template" name="badgeList" s={12} >
                
                <option defaultChecked onChange={console.log('boo changed')}>No</option>
                <option onChange={console.log('boo changed')}>boo</option>
    
                </Input> */}

                {this.props.templateList.length > 0 ? <Input s={12} label="" placeholder="Use template" name="badgeQuery" onFocus={this.handleBadgeSelect} onChange={this.handleChange}></Input> : ''}
                { this.state.badge ? this.props.templateList.map(template => {
                  const query = this.state.badgeQuery ? this.state.badgeQuery.toUpperCase() : ''
                  const result = template.data.templateName.toUpperCase()
                  const templateId = template.id
                      if(this.state.badge && result.includes(query)) {
                        
                      return(
                        
                          <Button className="ghostButton templateButton" 
                          tooltip={this.state.url + template.data.templateString}
                          onClick={() => { this.addToBadgeList(template) } }
                          // onClick={this.addToBadgeList(template)} 
                          key={template.id} id={template.id} >{template.data.templateName}</Button>
                          
                      )
                    }
                  }): ''}

                {/* if BadgeList is longer then 0, hide the source, medium, campaign, term and content input fields */}
                {this.state.badgeList.length == 0 ? 
                <Col>
                <SourceInput onFocus={this.closeBadgeSelect} className="autoInput" list={this.props.sources} updateState={this.handleSourceChange} userInfo={this.props.userInfo} type='source'/>
                <MediumInput onFocus={this.closeBadgeSelect} className="autoInput" list={this.props.mediumTypes} updateState={this.handleMediumChange} userInfo={this.props.userInfo} type='medium'/>
 
                
                <Input onFocus={this.closeBadgeSelect} type="select" label="Use existing or create a new campaign" name="newCampaign" s={12} value={this.state.newCampaign} onChange={this.handleChange}>
                  <option value="createNewCampaign" >"Create a new campaign"</option>
                  
                  { this.state.campaignList.map(campaign => {
                      return(
                        <option value={campaign.campaignName}>{campaign.campaignName}</option>
                      )
                    })}
                </Input>

                {this.state.newCampaign == "createNewCampaign" ? <Input type="text" label="campaign" name="campaign" s={12} value={this.state.campaign} onChange={this.handleChange}/> : "" }
                <Input onFocus={this.closeBadgeSelect} type="text" label="term" name="term" s={12} value={this.state.term} onChange={this.handleChange}/>
                <Input onFocus={this.closeBadgeSelect} type="text" label="content" name="content" s={12} value={this.state.content} onChange={this.handleChange}/>
                </Col>
                : ''}
            </Col>

          <Col s={10} m={10} l={5} offset="l1 m1 s1" className="utmPreview left-align grey-text">
          <h4 className="">Preview</h4>
          {!this.state.badgeList.length ? 
            <div>
                {this.state.forceLowerCase ? 
                <UtmListItem utmPreview={
                  this.state.newCampaign == "createNewCampaign" ?
                  <p>{`${this.state.url ? encodeURI(this.state.url).trim() : "www.utm-boss.com" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : "" }`.toLowerCase()}</p>
                  :
                  <p>{`${this.state.url ? encodeURI(this.state.url).trim().toLowerCase() : "www.utm-boss.com" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim().toLowerCase() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim().toLowerCase() : "" }${this.state.newCampaign ? "&utm_campaign=" + encodeURI(this.state.newCampaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim().toLowerCase() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim().toLowerCase() : "" }`}</p>
                } 
                key="1" link={""} type="utmPreview" creator={this.props.userInfo.profilePicture} forceLowerCase={this.state.forceLowerCase}/> 
                : 
                <UtmListItem utmPreview={
                  this.state.newCampaign == "createNewCampaign" ?
                  <p>{`${this.state.url ? encodeURI(this.state.url).trim() : "www.utm-boss.com" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.campaign ? "&utm_campaign=" + encodeURI(this.state.campaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : "" }`}</p>
                  :
                  <p>{`${this.state.url ? encodeURI(this.state.url).trim() : "www.utm-boss.com" }${this.state.source ? "?utm_source=" + encodeURI(this.state.source).trim() : "" }${this.state.medium ? "&utm_medium=" + encodeURI(this.state.medium).trim() : "" }${this.state.newCampaign ? "&utm_campaign=" + encodeURI(this.state.newCampaign).trim() : "" }${this.state.term ? "&utm_term=" + encodeURI(this.state.term).trim() : "" }${this.state.content ? "&utm_content=" + encodeURI(this.state.content).trim() : "" }`}</p>
                } 
                key="1" link={""} type="utmPreview" creator={this.props.userInfo.profilePicture} forceLowerCase={this.state.forceLowerCase}/> 
              }
            </div> : 
                //chips for the badge list
                <Row>
                {this.state.badgeList.length ? this.state.badgeList.map(item => {
                  return(
                    <Col className="noPadding"> 
                      <Chip className="badgeItem"> 
                        
                          {/* {item.data.templateName} */}
                          <p>{this.state.url + item.data.templateString}</p>
                        
                          <Button className="closeIcon" id={item} onClick={this.removeFromBadgelist}><Icon>close</Icon></Button>
                        
                      </Chip>
                    </Col>
                  )
                }): ''}
                </Row>

          }

            {this.state.badgeList.length !== 0 ? "" : 
            <div>
              <p>Force lowercase</p>
              <Input type="switch" label="forceLowerCase" name="forceLowerCase" s={12} checked={this.state.forceLowerCase} onChange={this.handleSwitch}/>
            </div>
          }
              {this.state.badgeList.length == 0 ? <Input label="Save as template" value={this.state.saveAsTemplate} onChange={this.handleChange} name="saveAsTemplate" /> : '' }
              
                <br/>
                <Row>
                  <Col s={12} m={12} l={12} >
                    { this.state.badgeList.length > 0 ? <Button className="primary" id="badgeUtms" onClick={this.handleBadgeUtms}>{`Create ${this.state.badgeList.length} ${this.state.badgeList.length == 1 ? "UTM" : "UTMS"}`}</Button> :
                      this.state.newCampaign == "createNewCampaign" ? <Button className="primary" id="logIn" onClick={this.createNewCampaign}>Create UTM & campaign</Button> :
                    <Button className="primary" id="logIn" onClick={this.createUTM}>Create UTM</Button>
                    }
                  </Col>
                  
                  </Row>
                </Col>

        </Col>
      </div>

    )
  }
}

export default Builder
