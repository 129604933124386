import firebase from 'firebase';

var config = {
  apiKey: "AIzaSyDUyoizdIrBVs69Vas32gZoVNSlwqRwgP8",
  authDomain: "utm-manager-2019.firebaseapp.com",
  databaseURL: "https://utm-manager-2019.firebaseio.com",
  projectId: "utm-manager-2019",
  storageBucket: "",
  messagingSenderId: "883362962554"
};

var configStaging = {
  apiKey: "AIzaSyDBGhQO5hx8aPm5MfSNq3jxtusLZs6Ak3U",
  authDomain: "staging-utm-boss.firebaseapp.com",
  databaseURL: "https://staging-utm-boss.firebaseio.com",
  projectId: "staging-utm-boss",
  storageBucket: "staging-utm-boss.appspot.com",
  messagingSenderId: "206674546928",
  appId: "1:206674546928:web:4757feb4e9f3d1e1"
};

const fire = firebase.initializeApp(config);

export default fire;
