import React, {Component} from 'react';
import {Button, Icon, Col, Card, Row, Table, Chip, Collection, CollectionItem} from 'react-materialize';
import './../ComponentCSS.css';
import UtmActions from './UtmActions';
import { NavLink } from 'react-router-dom';
import unknown from './../images/unknown.png';
import { toast } from 'react-toastify';
import ProfilePic from '../ProfilePic';
import { create } from 'domain';

function handleCopy(e) {
   
  const newInput = document.createElement('textarea');
      newInput.value = e.target.innerText;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand('copy');
      document.body.removeChild(newInput);
      toast("UTM Copied 🤘🏽", {
        draggablePercent: 60,
        className: 'notify-success',
        bodyClassName: "notify-text",
        progressClassName: 'notificationProgressBar'
      });
    }
 
class UtmListItem extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      data: '',
      creator: {}
    }
  }

  render() {
  
  if (this.props.type == "table") {
    
  return(
          <tr>
            <td>
            {this.props.data.fullUTM}
            </td>
            <td>
            {this.props.data.source}
            </td>
            <td>
            {this.props.data.medium}
            </td>
            <td>
            {this.props.data.campaign}
            </td>
            <td>
            {this.props.data.term}
            </td>
            <td>
            {this.props.data.content}
            </td>
            <td>
            <Button href={this.props.link} />
            </td>
          </tr>

  )} else if (this.props.type == "list") {
    
    let creatorProfilePic = '';
    let date = this.props.createDate.toString().split(' ')
    let createDate = date[1] + ' ' + date[2] + ' ' +date[3] + ' ' + date[4]
    console.log(createDate)

    return(
            <Collection >
            <CollectionItem className="avatar left-align utmListItem">
              <Row className="noBottomMargin">
              <br/>
                <Col >
                  <img src={this.props.creator ? this.props.creator.data.profilePicture : unknown} alt="" className="circle" />
                </Col>
                <Col className="fullUTM">
                  <span onClick={handleCopy} className="title fullUTMText">
                  {this.props.data.fullUTM}
                  </span>
                </Col>
                {/* <Col>
                  <Chip>
                  {this.props.data.source}/{this.props.data.medium}
                  </Chip>
                  <Chip>
                  {this.props.data.campaign}
                  </Chip>
                  {this.props.data.content ? 
                  <Chip>
                  {this.props.data.content}
                  </Chip> 
                  : ''}
                  {this.props.data.term ? 
                  <Chip>
                  {this.props.data.term}
                  </Chip> 
                  : ''}
                  </Col> */}

                  <Col l={6} m={6} s={6}>
                  <p class="grey-text">{createDate}</p>
                  </Col>
                  <Col l={6} m={6} s={6}>
                  
                  <UtmActions editLink={this.props.link} data={this.props.data.fullUTM} id={this.props.id} />
                  </Col>
                </Row>
              

            </CollectionItem>
            </Collection>

    )
  
  } if (this.props.type == "utmPreview") {
    return(
            <Collection >
            <CollectionItem className="avatar left-align utmListItem">
              <Row className="noBottomMargin">
              <br/>
                <Col >
                <img src={this.props.creator ? this.props.creator : unknown} alt="" className="circle" />
                </Col>
                <Col className="fullUTM">
                  <span onClick={handleCopy} className="title fullUTMText">
                  {this.props.forceLowercase ? this.props.utmPreview.toLowerCase() : this.props.utmPreview}
                  </span>
                </Col>
                
                </Row>
              

            </CollectionItem>
            </Collection>

      )
    }
  }
}

export default UtmListItem
