import React, {Component} from 'react';
import logo from '../logo.svg';
import { Button, Card, Row, Col, ProgressBar, Input, Tabs, Tab} from 'react-materialize';
import * as firebase from 'firebase';
import fire from '../fire';
import ComponentCSS from '../ComponentCSS.css';
import { NavLink } from 'react-router-dom';

class JoinTeam extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.state = {
      email: '',
      emailVerified: '',
      password: '',
      firstName: '',
      lastName: '',
      displayName: '',
      team: ''
    }
  }

  handleSignup(e) {
    e.preventDefault();
      const db = firebase.firestore();
      //check if user is on signup list
      
      const email = this.state.emailVerified;
      const password = this.state.password;
      db.collection("user").where("email", "==", email).get()
      .then((querySnapshot) => {
        querySnapshot.forEach(doc => {
           
        // doc.data() is never undefined for query doc snapshots
        
          //if user exists
          if (doc.exists) {
            //create the user here
            fire.auth().createUserWithEmailAndPassword(email, password)
                .then((u) => {
                    //create the user document in the database
                    //const user = u.user.uid
                    
                    const db = firebase.firestore();

                    db.collection("user").doc(u.user.uid).set({
                        teamRef: doc.data().teamRef,
                        team: doc.data().team,
                        email: doc.data().email
                      })

                      //update the team users array
                      db.collection("team").doc(doc.data().teamId).update({
                        users: firebase.firestore.FieldValue.arrayUnion(u.user.uid)
                      })
                      

                      //delete the queued user data
                        db.collection("user").doc(doc.id).delete().then(function() {
                            
                        }).then((u) => {
                            //redirect to the dashboard
                            window.location.pathname="/";
                          })
                          .catch(function(error) {
                              console.error("Error removing document: ", error);
                              window.alert(error)
                            }); 
            
            })
            
            .catch(function(error) {
                console.error("Error removing document: ", error);
                window.alert(error)
              });
            }
            else {
              window.alert("It seems that this email is not known in our system. Please check and retry or sign up as a new user");
                };
            });
        })
            .catch((error) => {
                
                window.alert(error)
            })
    }

  handleChange(e) {
    
    this.setState({ [e.target.name]: e.target.value });
    
  }


render() {

    return(
      <Row>
        <Col className="loginSignupCol" s={10} m={4} l={4} offset="l4 m4 s1">

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Join an existing team</h4>

                <Input type="email" label="Email" name="emailVerified" s={12} value={this.state.emailVerified} onChange={this.handleChange}/>
                <Input type="password" label="Password" name="password" s={12} value={this.state.password} onChange={this.handleChange}/>
                <Input type="text" label="Company name" name="team" s={12} value={this.state.team} onChange={this.handleChange}/>

              <Row>
                <Col s={6} m={6} l={6} >
                  <Button className="primary" id="SignUp" onClick={this.handleSignup}>Sign up</Button>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={12} l={12} >
                  <NavLink to="/">
                    <p>Already a user? Sign in here</p>
                  </NavLink>
                  <NavLink to="/create-account">
                    <p>New here? Sign up here</p>
                  </NavLink>
                </Col>
              </Row>
          </Col>

        </Col>
      </Row>
    )
  }
}

export default JoinTeam
