import React, { Component } from 'react';
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import './../ComponentCSS.css';

  // Call it once in your app. At the root of your app is the best place
  toast.configure()

  const Notify = (props) => {
    const notification = () => toast(props.text, {
      draggablePercent: 60,
      className: 'notify-background',
      bodyClassName: "grow-font-size",
      progressClassName: 'notificationProgressBar'
    });

    return <button onClick={notification}>Notification works !</button>;
  }

  export default Notify
