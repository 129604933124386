import React, {Component} from 'react';
import './ComponentCSS.css';
import { Icon, Button, Col, Row} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './fire';

class LogOut extends Component {

  render() {

    function handleLogOut() {
      firebase.auth().signOut().then(function() {
        window.location.pathname = "/"
      }).catch(function(error) {
        // An error happened.
      });
    }

    return(
      <div className="MenuItem" onClick={handleLogOut}>
        <Icon id={this.props.item}>rowing</Icon>
        <h5 className="menuItemText">Log out</h5>

      </div>
    )
  }
}

export default LogOut
