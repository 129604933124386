import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button, Card, Row, Col, ProgressBar } from 'react-materialize';
import Maincontent from './MainContent';
import SideNav from './SideNav';
import SubNav from './SubNav';
import utmList from './data.js'
import * as firebase from 'firebase';
import fire from './fire';
import LoginSignup from './LoginSignup';
import Builder from './components/builder';
import Dashboard from './Dashboard';
import PageNotFound from './PageNotFound';
import { User } from './providers/user';
import { Data } from './providers/data';
import { Campaigns } from './providers/Campaigns';
import { UserInfo } from './providers/userinfo';
import { TeamInfo } from './providers/teaminfo';
import UtmOverview from './UtmOverview';
import Dashboards from './components/Dashboard';
import Users from './components/Users';
import ProfileSettings from './components/ProfileSettings';
import TeamSettings from './components/TeamSettings';
import SingleUtm from './components/SingleUtm';
import CreateAccount from './createAccount';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Notify from './components/notify';
import CampaignsOverview from './components/Campaigns';
import JoinTeam from './components/JoinTeam';



import { BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

class App extends Component {
  constructor() {
    super()
      this.handleMainContentState = this.handleMainContentState.bind(this);
      this.authListener = this.authListener.bind(this);
      this.getCampaignData = this.getCampaignData.bind(this);
      this.handlePersonalState = this.handlePersonalState.bind(this);
    this.state = {
        team: {},
        user: {},
        userInfo: {},
        location: 'Dashboard',
        utmList: [],
        campaigns: [],
        teamMembers: [],
        templateList: [],
        personalMode: ''
      }
    }


    authListener() {
      fire.auth().onAuthStateChanged((user) => {
       if (user) {
         // User is signed in
         

         const db = firebase.firestore();

         // get user meta data from firebase
         db.collection("user").doc(user.uid).get()
          .then((doc) => {
            
            if (doc.exists) {
              this.setState({
                userInfo: doc.data(),
                personalMode: doc.data().personalMode
                  })
                  //get all the right campaigns from the right team
                  db.collection("campaign").where("teamRef", "==", doc.data().teamRef).orderBy("createDate","desc")
                   .onSnapshot((querySnapshot) => {
                     
                    var campaigns = [];
                    querySnapshot.forEach((campaignDoc) => {

                         function campaignObject(campaignId, campaignData) {
                           this.id = campaignId;
                           this.data = campaignData;
                         }

                         var newCampaignObject = new campaignObject(campaignDoc.id, campaignDoc.data())
                         
                        campaigns.push(newCampaignObject);
                    });
                    
                    this.setState({
                      campaigns: campaigns
                    })
                  })

                  //get utms here from the right team
                  // firebase.database().ref('users');
                  db.collection("utm").where("teamRef", "==", doc.data().teamRef).orderBy("createDate","desc")
                   .onSnapshot((querySnapshot) => {
                    var utms = [];
                    querySnapshot.forEach(function(doc) {

                         function UtmObject(utmId, utmData) {
                           this.id = utmId;
                           this.data = utmData;
                         }

                         var newUtmObject = new UtmObject(doc.id, doc.data())

                        utms.push(newUtmObject);
                    });

                  this.setState({
                    user: user,
                    utmList: utms
                      })
                    })

                    //get templates from the right team
                    db.collection("template").where("teamRef", "==", doc.data().teamRef).orderBy("createDate","desc")
                   .onSnapshot((querySnapshot) => {
                    var templates = [];
                    
                    querySnapshot.forEach(function(doc) {

                         function templateObject(templateId, templateData) {
                           this.id = templateId;
                           this.data = templateData;
                         }

                         var newtemplateObject = new templateObject(doc.id, doc.data())

                        templates.push(newtemplateObject);
                    });

                  this.setState({
                    user: user,
                    templateList: templates
                      })
                    })

                    //get team info from firebase
                  const teamId = doc.data().teamRef.id;
                    db.collection("team").doc(teamId)
                    .onSnapshot((teamInfo) => {
                    // .then((teamInfo) => {
                      

                        this.setState({
                          team: teamInfo.data()
                            })
                          })

                  //get all users from the right team
                  db.collection("user").where("teamRef", "==", doc.data().teamRef)
                   .onSnapshot((querySnapshot) => {
                     
                    var teamMembers = [];
                    querySnapshot.forEach((userDoc) => {

                         function campaignObject(userId, userData) {
                           this.id = userId;
                           this.data = userData;
                         }

                         var newUserDoc = new campaignObject(userDoc.id, userDoc.data())
                         
                        teamMembers.push(newUserDoc);
                    });
                    
                    this.setState({
                      teamMembers: teamMembers
                    })
                  })

            } else {
              
            }
          })
          .catch(function(error) {
              
            });

         } else {
             // User is signed out.
             this.setState({
               user: null
             })
           }
       })
     }

     //get campaign data from firebase
  getCampaignData(teamRef) {
    const db = firebase.firestore();
     

   }

    handleMainContentState(e) {
      this.setState({
        location: "UTM-Builder"
      })
    }

    handlePersonalState(e) {
      this.setState({
        personalMode: !this.state.personalMode
      })

        const db = firebase.firestore();
        const oldState = this.state.personalMode
        db.collection("user").doc(fire.auth().currentUser.uid).update({
          personalMode: !oldState
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });

    }

    componentWillMount() {
        this.authListener()
        
        // Call it once in your app. At the root of your app is the best place

        // const db = firebase.firestore();
        // db.collection("utm").where("team", "==", "Foleon")
        // .onSnapshot(function(querySnapshot) {
        //   var utms = [];
        //   querySnapshot.forEach(function(doc) {
        //       utms.push(doc.data());
        //   });
        //   
        //   this.setState({
        //     utmList: utms
        //   })
        // }.bind(this));

        }

  render() {

    //here comes js
    // {this.state.user == null ? "" : <SubNav location={this.state.location} data={this.state.utmList}/>}
    //user={this.state.user} data={this.state.utmList} handleState={this.handleMainContentState.bind(this)
    if(this.state.user == null || this.state.userInfo == null || this.state.utmList == null || this.state.campaigns == null) {
      return (
        <Router>
          <Switch>

            <Route path="/" exact component={LoginSignup}/>
            <Route path="/create-account" exact component={CreateAccount}/>
            <Route path="/join-a-team" exact component={JoinTeam}/>
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      )
    } else {

          return (
            <Data.Provider value={this.state.utmList}>
            <User.Provider value={this.state.user}>
            <UserInfo.Provider value={this.state.userInfo}>
            <Campaigns.Provider value={this.state.campaigns}>
            <TeamInfo.Provider value={this.state.team}>
              <Router>
                <div className="App">
                  <SideNav user = {this.state.user} personalMode={this.state.personalMode} handlePersonalState={this.handlePersonalState} userInfo={this.state.userInfo}/>

                  <Switch>

                    <Route path="/" exact render={(props) => <Dashboards routeProps={props} userInfo={this.state.userInfo} user={this.state.user} data={this.state.utmList} campaigns={this.state.campaigns} team={this.state.team} teamMembers={this.state.teamMembers} templateList={this.state.templateList} personalMode={this.state.personalMode}/>} />

                    <Route path="/builder" render={(props) => <Builder routeProps={props} userInfo={this.state.userInfo} user={this.state.user} campaigns={this.state.campaigns} /> } />
                    
                    <Route path="/campaigns" exact render={(props) => <CampaignsOverview routeProps={props} userInfo={this.state.userInfo} user={this.state.user} data={this.state.utmList} campaigns={this.state.campaigns} team={this.state.team} teamMembers={this.state.teamMembers} templateList={this.state.templateList} personalMode={this.state.personalMode} />} />
                    {/* <Route path="/campaigns" exact render={(props) => <Maincontent routeProps={props} userInfo={this.state.userInfo} user={this.state.user} data={this.state.utmList} campaigns={this.state.campaigns} team={this.state.team} teamMembers={this.state.teamMembers} templateList={this.state.templateList} />} /> */}
                    <Route path="/campaigns/:id" exact render={(props) => <UtmOverview routeProps={props} userInfo={this.state.userInfo} user={this.state.user} campaigns={this.state.campaigns} team={this.state.team} data={this.state.utmList} teamMembers={this.state.teamMembers} personalMode={this.state.personalMode} /> }/>
                    <Route path="/campaigns/:id/utm/:utmId" exact component = {SingleUtm}/>
                    <Route path="/users" exact render={(props) => <Users data={this.state.team}/>} />
                    <Route path="/users/:id" exact render={(props) => <ProfileSettings data={this.state.user} userId={this.state.user.uid}/>} />
                    <Route path="/settings/:id" exact render={(props) => <ProfileSettings data={this.state.user} userId={this.state.user.uid}/>} />
                    <Route path="/settings" exact render={(props) => <TeamSettings data={this.state.user} userId={this.state.user.uid} team={this.state.team} data={this.state.utmList} templates={this.state.templateList}/>} />

                    <Route component={PageNotFound} />

                  </Switch>

                </div>
              </Router>
                  </TeamInfo.Provider>
                </Campaigns.Provider>
              </UserInfo.Provider>
            </User.Provider>
          </Data.Provider>
            );
          }
    }
  }


export default App;
