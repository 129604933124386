import React, { Component } from "react";
import './MainContent.css';
import './ComponentCSS.css';
import { Button, Card, Row, Col } from 'react-materialize';
import { Route, Link } from 'react-router-dom';
class PageNotFound extends Component {

  render() {
    return (

        <Row>
          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h1>PageNotFound</h1>
          </Col>
        </Row>
    );
  }
}

export default PageNotFound;
