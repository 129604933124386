import React, { Component } from 'react';
import { Button, Card, Row, Col, CSS, Input} from 'react-materialize';
import './SideNav.css';
import logo from "./logo.svg";
import logoutmboss from "./images/logo-utm-boss.svg" 
import hexLogo from "./svg/hex-logo.svg";
import ProfilePic from './ProfilePic';
import MenuBar from './MenuBar';
import { NavLink } from 'react-router-dom';
import fire from "./fire";
import * as firebase from 'firebase';

class SideNav extends Component {
  constructor(props) {
    super(props)
    this.handleMode = this.handleMode.bind(this)
    this.state = {
      personalMode: ''
    }
  }

  componentWillMount() {
    console.log(this.props)
    this.setState({
      personalMode: this.props.personalMode
    });
  }

  // setProps() {
    
  // }

  handleMode(e) {
    console.log(this.props.userInfo.personalMode)
      this.setState({
        personalMode: !this.state.personalMode
      });

    const db = firebase.firestore();
    const oldState = this.state.personalMode
    db.collection("user").doc(fire.auth().currentUser.uid).update({
      personalMode: !oldState
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
    
  }

    render() {

      const userInformation = this.props.user
      console.log(userInformation.uid);

      return(

        <div className="navBase">

              <div className="logo">
                  <img className='spinning logoUtmBoss' src={logoutmboss} />
              </div>
              <NavLink to={`/settings/${userInformation.uid}`}>
                <ProfilePic user={this.props.user.displayName} profilePic={this.props.user.photoURL}/>
              </NavLink>
              <Row>
              <Col>
                <p className="grey-text">Show my UTMs only</p>
                <Input type="switch" onLabel=" " offLabel=" " name="mode" s={12} checked={this.props.personalMode} onChange={this.props.handlePersonalState}/>
            </Col>
            </Row>
              <MenuBar data={this.props.user.uid}/>

        </div>

      )
    }

}
export default SideNav;
