import React, {Component} from 'react';
import './ComponentCSS.css';
import MenuItem from './MenuItem';
import dashboardIcon from './svg/dashboardIcon.svg';
import campaignIcon from './svg/campaigns.svg';
import settingsIcon from './svg/settingsIcon.svg';
import users from './svg/users.svg';
import { Icon } from 'react-materialize';
import LogOut from './LogOut';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';

class MenuBar extends Component {


  render() {
    // here comes js
    const settingsLink = `/settings/${this.props.data}`;
    return(

      <div className='MenuBar'>
        <NavLink to='/' activeClassName="activeMenuItem" exact={true}>
          <MenuItem type="Dashboard" item="dashboard" source={dashboardIcon} />
        </NavLink>
        <NavLink to='/campaigns' activeClassName="activeMenuItem hover">
          <MenuItem type="Campaigns" item="folder" source={campaignIcon} />
        </NavLink>
        <NavLink to='/settings' activeClassName="activeMenuItem hover">
          <MenuItem type="Settings" item="settings" source={settingsIcon} />
        </NavLink>
        <NavLink to='/users' activeClassName="activeMenuItem hover">
          <MenuItem type="Users" item="people" source={users} />
        </NavLink>
        <LogOut />

      </div>

    )
  }
}

export default MenuBar
