import React, { Component } from "react";
import './../MainContent.css';
import './../ComponentCSS.css';
import UtmItem from './../UtmItem';
import {Data} from './../providers/data';
import { Campaigns } from './../providers/Campaigns';
import { Button, Card, Row, Col, Input, Icon, Table } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './../CampaignItem';
import SubNav from './../SubNav'
import { UserInfo } from './../providers/userinfo';
import { TeamInfo } from './../providers/teaminfo';
import * as firebase from 'firebase';
import fire from './../fire';
import DeleteItem from './deleteItem';

class Users extends Component {
  constructor(props) {
    super(props)
    this.getUsers = this.getUsers.bind(this)
    this.handleSignup = this.handleSignup.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getTeamMembers = this.getTeamMembers.bind(this)
    this.state = {
      filter: '',
      rights: 'admin',
      users: null,
      teamMembers: [],
      teamId: '',
      teamName: '',
      emailVerified: '',
      password: ''
    }
  }

  handleSignup(e) {
    e.preventDefault();

    const db = firebase.firestore();

    db.collection("user").add({
      team: this.state.teamName,
      teamRef: db.collection("team").doc(this.state.teamId),
      teamId: this.state.teamId,
      userQueue: true,
      email: this.state.emailVerified
    }).then(() => {
      const signUpInput = document.getElementById('userSignUp');
      signUpInput.value = '';
  })
}

  handleChange(e) {

    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
      this.getUsers()
      this.getTeamMembers()
  }

  getUsers() {
      const users = this.props.data.users
      
      const userIds = []
      if (users) {
        users.map(user => {
            userIds.push(user)
            
      })
    this.setState({
      users: userIds,
      teamId: this.props.data.teamId,
      teamName: this.props.data.teamName
    })
  }
}

  getTeamMembers() {
    const db = firebase.firestore();
    const teamRef = db.collection('team').doc(this.props.data.teamId)

    db.collection('user').where("teamRef", "==", teamRef)
    .onSnapshot((querySnapshot) => {
     var teamMembers = [];
     querySnapshot.forEach(function(member) {

          function memberObject(utmId, utmData) {
            this.id = utmId;
            this.data = utmData;
          }

          var newmemberObject = new memberObject(member.id, member.data())

         teamMembers.push(newmemberObject);
     });

   this.setState({
     teamMembers: teamMembers
       })
     })

  }


  render() {

    return (
      <TeamInfo.Consumer>{(dataSet) => {

        return (
              <div className="MainContent">

                  <div className="MainContent-header">

                    <div className="mainContentUtm">
                      <Row>

                        <Col s={8} m={8} l={8} offset="l2 m2 s2" className="searchbar">
                          <Input s={12} label="" defaultValue="Search" disabled ><Icon className="md-dark md-inactive">search</Icon></Input>
                        </Col>
                      </Row>
                      <Row>
                        <Col s={8} m={8} l={8} offset="l2 m2 s2" className="campaignHeader">
                            <Col s={8} m={8} l={8} offset="l2 m2 s2">
                              <h4>Manage your users for {this.props.data.teamName}</h4>
                            </Col>
                        </Col>

                      </Row>
                    </div>

                    <div className="utmGrid">
                      <Row>
                        <Col s={8} m={8} l={8} offset="l2 m2 s2">
                          <Col s={12} m={6} l={6} >
                            <Input type="email" label="Email" name="emailVerified" id="userSignUp" s={12} onChange={this.handleChange} />
                          </Col>
                          <Col s={12} m={6} l={6} >
                            <Button className="primary" onClick={this.handleSignup} >Add new user</Button>
                          </Col>

                          <Table hoverable={true} responsive={true}>
                              <thead>
                                <tr>
                                  <th data-field="id">Name</th>
                                  <th data-field="Role">Role</th>
                                  <th data-field="Action">Action</th>
                                </tr>
                              </thead>

                              <tbody>

                                  {this.state.teamMembers ? this.state.teamMembers.map(user => {

                                    const db = firebase.firestore();

                                      if(user.data.userQueue == true) {

                                      return (

                                        <tr className="pendingUser">
                                          <td>{user.data.email ? user.data.email : "Unknown" }</td>
                                          <td>{"Pending" }</td>
                                          <td><DeleteItem id={user.id} type="user" /></td>
                                        </tr>
                                      )
                                    } else {
                                      return (

                                        <tr>
                                          <td>{user.data.email ? user.data.email : "Unknown" }</td>
                                          <td>{user.data.role ? user.data.role : "Unknown" }</td>
                                          <td><DeleteItem id={user.id} type="user" /></td>
                                        </tr>
                                      )
                                    }
                                    }
                                  ): "" }
                              </tbody>
                          </Table>
                        </Col>

                      </Row>
                    </div>
                  </div>


                </div>
            )
    }
  }</TeamInfo.Consumer>
    );
  }
}

export default Users;
