import React, {Component} from 'react';
import logo from './logo.svg';
import { Button, Card, Row, Col, ProgressBar, Input, Tabs, Tab} from 'react-materialize';
import * as firebase from 'firebase';
import fire from './fire';
import ComponentCSS from './ComponentCSS.css';
import { NavLink } from 'react-router-dom';

class LoginSignup extends Component {
  constructor() {
    super()
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: '',
      emailVerified: '',
      password: '',
      firstName: '',
      lastName: '',
      displayName: '',
      team: ''
    }
  }

  handleLogin(e) {
    e.preventDefault();
    fire.auth().signInWithEmailAndPassword(this.state.emailVerified, this.state.password).then((u) => {
      
    })
    .catch((error) => {
      
      
      window.alert(error)
    })
  }

  handleChange(e) {

    this.setState({ [e.target.name]: e.target.value });
  }


render() {

    return(
      <Row>
        <Col className="loginSignupCol" s={10} m={10} l={4} offset="l4 m1 s1">

          <Col s={10} m={10} l={10} offset="l1 m1 s1">
            <h4 className="loginSignupHeader">Log in</h4>

                <Input type="email" label="Email" name="emailVerified" s={12} value={this.state.emailVerified} onChange={this.handleChange}/>
                <Input type="password" label="password" name="password" s={12} value={this.state.password} onChange={this.handleChange}/>

              <Row>
                <Col s={12} m={12} l={12} >
                  <Button className="primary" id="logIn" onClick={this.handleLogin}>Log in</Button>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={12} l={12} >
                <NavLink to="/create-account">
                  <a>New? Sign up here</a>
                </NavLink>
                <NavLink to="/join-a-team">
                    <p>Join an existing team</p>
                  </NavLink>
                </Col>
                </Row>
          </Col>

        </Col>
      </Row>
    )
  }
}

export default LoginSignup
