import React, { Component } from "react";
import './../MainContent.css';
import './../ComponentCSS.css';
import UtmItem from './../UtmItem';
import {Data} from './../providers/data';
import { Campaigns } from './../providers/Campaigns';
import { Button, Card, Row, Col, Input, Icon, Tab, Tabs } from 'react-materialize';
import { BrowserRouter as Router, Route, NavLink, Switch} from 'react-router-dom';
import CampaignItem from './../CampaignItem';
import SubNav from './../SubNav'
import { UserInfo } from './../providers/userinfo';
import { TeamInfo } from './../providers/teaminfo';
import * as firebase from 'firebase';
import fire from './../fire';
import ProfilePic from './../ProfilePic';
import TeamSettingsInputField from './TeamSettingInputField';
import SourceMediumTab from './SourceMediumTab';
import { ExportToCsv } from 'export-to-csv';
import EditTemplate from './EditTemplate';
import DeleteItem from './deleteItem'
class TeamSettings extends Component {
  constructor(props) {
    super(props)
    this.setInitialState = this.setInitialState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onUpdateItem = this.onUpdateItem.bind(this)
    this.handleNewSource = this.handleNewSource.bind(this)
    this.handleChangeSource = this.handleChangeSource.bind(this)
    this.state = {
      filter: '',
      fullName: '',
      role: '',
      profileLink: '',
      rights: 'admin',
      teamId: '',
      teamName: '',
      userId: '',
      sources: [],
      mediumTypes: [],
      templateList: [],
      changesMade: false,
      addNewSource: '',
      activeTab: 'sources',
      newSource: ''
    }
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ 
        [e.target.name]: e.target.value 
    });
  }

  handleChangeSource(e) {
    console.log(e.target.name, e.target.value)
    this.setState({ [e.target.name]: e.target.value });
  }

    onUpdateItem = (i, newItem, tab) => {
      const updatedList = tab == "sources" && this.state.sources ? this.state.sources : this.state.mediumTypes;
      
      const sourcePlaceholder = []; 

      for (let [key, value] of Object.entries(updatedList)) {
        
        if (i == key) {
          
          sourcePlaceholder.push(newItem)
        } else {
          sourcePlaceholder.push(value)
        }
        if(sourcePlaceholder.length === updatedList.length) {
          
          this.setState({
            [tab]: sourcePlaceholder,
            activeTab: tab
          })
        }
        
      }
    };

    handleNewSource(e) {
      e.preventDefault();

      const db = firebase.firestore();

    db.collection("team").doc(this.props.team.teamId).update({
      sources: firebase.firestore.FieldValue.arrayUnion(this.state.newSource)
      }).then(() => {
        this.setState({
          newSource: ''
        })
    })
  }

  componentDidMount() {
      this.setInitialState()
  }

  setInitialState() {

    this.setState({
      users: this.props.team.users,
      teamId: this.props.team.teamRef,
      teamName: this.props.team.teamName,
      userId: this.props.userId,
      sources: this.props.team.sources,
      mediumTypes: this.props.team.mediumTypes
    })
  }

  render() {
    
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'My UTM Lists',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    let data = []
    let UTMData = this.props.data.map(function(utm) {
      let constructUTM = {
        url: utm.data.url, 
        campaign: utm.data.campaign, 
        source: utm.data.source, 
        medium: utm.data.medium, 
        content: utm.data.content, 
        term: utm.data.term, 
        fullUTM: utm.data.fullUTM 
      }
      data.push(constructUTM)
    })

    const csvExporter = new ExportToCsv(options);
    return (
      
              <div className="MainContent">

                  <div className="MainContent-header">

                    <div className="mainContentUtm">

                      <Row>
                        <Col s={8} m={8} l={8} offset="l2 m2 s2" className="campaignHeader">
                            <Col s={8} m={8} l={8} offset="l2 m2 s2">
                              <h2>Settings</h2>
                              <p>Welcome to your team settings page{this.props.team.teamName ? ` for ${this.props.team.teamName}` : ''}. Here you can manage your UTM templates and your presets for sources and medium types.</p>
                              <Button className="ghostButton" onClick={() => { csvExporter.generateCsv(data)} }>Export all UTMS</Button>
                            </Col>
                        </Col>

                      </Row>
                    </div>

                    <div className="utmGrid">
                      <Row>
                        <Col className="utmStats " s={8} m={8} l={8} offset="l2 m2 s2">
                              <Col className="profileSettingsCard" s={12} m={12} l={12}>
                                  <h5 data-field="id">Attribution settings</h5>
                                  <Col s={12} m={6} l={6} >
                                            <input type="text"  name="newSource" value={this.state.newSource} onChange={this.handleChangeSource}/>
                                            {/* <Input label="Add source" value={this.state.newSource} name="newSource" id="addNewSource" s={12} onChange={this.handleChangeSource} /> */}
                                          </Col>
                                          <Col s={12} m={6} l={6} >
                                            <Button className="primary" onClick={this.handleNewSource} >Add new source</Button>
                                          </Col>
                                  <Tabs className='tab-demo tabs-fixed-width z-depth-1' tabOptions={[{swipable: true}, {responsiveThreshold: 1300}]}>
                                  <Tab active={this.state.activeTab == "sources" ? true : false} title={this.props.team.sources ? `Sources (${this.props.team.sources.length})` : "Sources" }>
                                    <Row className="tabsourcemedium">
                                    <Col s={12} m={12} l={12}>
                                      <Col s={12} m={12} l={12} >
                                      
                                          <Col s={12} m={12} l={12} className="left-align">
                                          
                                            {this.state.sources ? this.state.sources.map((item, index) => {
                                              
                                                return(
                                                    <TeamSettingsInputField value={item} sources={this.state.sources} type={"sources"} teamId={this.props.team.teamId} key={index} index={index} updateParent={this.onUpdateItem}/>
                                                    
                                                )
                                            }) : ''
                                            }
                                                </Col>
                                            </Col>
                                        </Col>
                                        </Row>
                                    </Tab>
                                    
                                    {/* here comes medium types tab list */}
                                    <Tab active={this.state.activeTab == "mediumTypes" ? true : false} title={this.props.team.mediumTypes ? `Medium Types (${this.props.team.mediumTypes.length})` : "Medium Types" }>

                                    <Row className="tabsourcemedium">
                                    <Col s={12} m={12} l={12}>
                                      <Col s={12} m={12} l={12} >
                                          <Col s={12} m={12} l={12} className="left-align">
                                          
                                          {this.state.mediumTypes ? this.state.mediumTypes.map((item, index) => {
                                              
                                                return(
                                                    <TeamSettingsInputField value={item} sources={this.state.mediumTypes} type={"mediumTypes"} teamId={this.props.team.teamId} key={index} index={index} updateParent={this.onUpdateItem}/>
                                                    
                                                )
                                            }) : ''
                                            }
                                                </Col>
                                            </Col>
                                        </Col>
                                        </Row>
                                    </Tab>
                                    
                                    <Tab active={this.state.activeTab == "templates" ? true : false} title={this.props.templates ? `Templates (${this.props.templates.length})` : "Templates" } updateParent={this.onUpdateItem}>
                                    <Row className="tabsourcemedium">
                                      <Col s={12} m={12} l={12}>
                                        <Col s={12} m={12} l={12} >
                                            <Col s={12} m={12} l={12} className="left-align">
    
                                            {this.props.templates ? this.props.templates.map((item, index) => {
                                                
                                                  return(
                                                      //<TeamSettingsInputField value={item} sources={this.state.mediumTypes} type={"mediumTypes"} teamId={this.props.team.teamId} key={index} index={index} updateParent={this.onUpdateItem}/>
                                                      <Col s={12}>
                                                      <Col s={8}>
                                                      <h6 className="secundary">{item.data.templateName}</h6>
                                                      <p>{item.data.templateString}</p>
                                                      </Col>
                                                      <Col s={2}>
                                                      <EditTemplate data={item.data} templateId={item.id}/>
                                                      {/* <Button icon='edit' tooltip="Edit template" className='ghostButtonNoBorder waves'></Button> */}
                                                      </Col>
                                                      <Col s={2}>
                                                      <DeleteItem type="template" id={item.id}/>
                                                      {/* <Button icon='edit' tooltip="Edit template" className='ghostButtonNoBorder waves'></Button> */}
                                                      </Col>
                                                      </Col>
                                                  )
                                              }) : ''
                                              }
                                                  </Col>
                                              </Col>
                                          </Col>
                                    </Row>
                                    </Tab>
                                    
                                    {/* <Tab title="Content (coming soon)">
                                    <Row className="tabsourcemedium">
                                            <p>Coming soon...</p>
                                    </Row>
                                    </Tab> */}
                                </Tabs>
                                 
                              </Col>


                        </Col>

                      </Row>
                    </div>
                  </div>


                </div>
            )
    
  }
}

export default TeamSettings;
